import { Inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AplicativoFacade } from '@vip/state/aplicativo';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { ClienteFacade } from '@vip/state/cliente';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { SessaoFacade } from '@vip/state/sessao';
import {
  TipoEntregaFacade,
  TipoEntregaUtilsService,
} from '@vip/state/tipo-entrega';
import { filter, tap } from 'rxjs/operators';
import { ApiOrganizacaoService } from '@vip/api';
import { EnvironmentService } from '@vip/core';
import { mergeTakeOne } from '@vip/state/utils';
import { LojaTokenService } from '@vip/api';
import { IStorage, STORAGE } from '@vip/core';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { FirebaseService } from '@vip/native/firebase';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class StartupService {
  gaTag!: string;
  gaTag$ = new Subject<string>();
  constructor(
    private aplicativoFacade: AplicativoFacade,
    private clienteFacade: ClienteFacade,
    private filialFacade: FilialFacade,
    private sessaoFacade: SessaoFacade,
    private tipoEntregaFacade: TipoEntregaFacade,
    private enderecoFacade: EnderecoFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private firebaseService: FirebaseService,
    private lojaTokenService: LojaTokenService,
    private apiOrganizacaoService: ApiOrganizacaoService,
    private environmentService: EnvironmentService,
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private route: Router,
    @Inject(STORAGE) readonly storage: IStorage,
    private tipoEntregaUtilsService: TipoEntregaUtilsService,
  ) {}

  asyncStartup() {
    return async () => {
      try {
        await this.apiOrganizacaoService.getFilialByFilialDomain(
          this.environmentService.getHost(),
        );
        await this.lojaTokenService.init();
        this.aplicativoFacade.initFromVipcommerceFilialId(
          this.environmentService.getVipcommerceFilialId(),
        );
        if ('Notification' in window && 'serviceWorker' in navigator) {
          await this.firebaseService.initializeApp();
        }
        await new Promise((resolve, reject) => {
          combineLatest([
            this.filialFacade.isLoaded$,
            this.aplicativoFacade.error$,
          ])
            .pipe(
              mergeTakeOne(this.filialFacade.error$),
              filter(
                ([[filialIsLoaded, aplicativoError], filialError]) =>
                  filialIsLoaded === true || !!aplicativoError || !!filialError,
              ),
              tap(() => {
                this.filialFacade.filial$.subscribe((filial) => {
                  this.gaTag = filial.ga4 || '';
                  this.gaTag$.next(this.gaTag);
                });
              }),
            )
            .subscribe(
              () => {
                this.verificarLoginViaToken();
                this.sessaoFacade.init();
                this.tipoEntregaFacade.init();
                this.enderecoFacade.init();

                resolve(true);
              },
              () => reject(false),
            );
        });

        this.tipoEntregaFacade.init();
        this.enderecoFacade.init();
        this.compraEmProcessoFacade.init();
        this.tipoEntregaUtilsService.listenTiposEntregasEnderecoLoading();
      } catch (e: any) {
        this.route.navigateByUrl('manutencao', {
          state: { rota: 'filial', error: e.status },
        });
        console.error(e);
      }
    };
  }

  verificarLoginViaToken() {
    const params = new URLSearchParams(window.location.search);
    const inApp = params.get('inApp');
    const token = params.get('token');
    const cliente = params.get('cliente') ?? '';
    if (inApp === 'true' && token && this.decodeCliente(cliente)) {
      this.clienteFacade.loginViaToken(token, this.decodeCliente(cliente));
    } else {
      this.clienteFacade.init();
    }
  }

  decodeCliente(clienteEncoded: string) {
    try {
      return atob(clienteEncoded);
    } catch (_) {
      return '';
    }
  }
}
