import { Action, createReducer, on } from '@ngrx/store';

import { ICartao } from '@vip/core';
import * as CartaoActions from './cartao.actions';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const CARTAO_FEATURE_KEY = 'cartao';

export interface CartaoState extends IGenericState<EntityState<ICartao>> {
  cartaoSelecionadoId: string | null;
}

export const cartaoAdapter: EntityAdapter<ICartao> =
  createEntityAdapter<ICartao>();

export const initialState: CartaoState = {
  data: cartaoAdapter.getInitialState(),
  cartaoSelecionadoId: null,
  status: GenericStoreStatusEnum.PENDING,
  error: null,
};

const cartaoReducer = createReducer(
  initialState,
  on(CartaoActions.getCartoes, CartaoActions.deletarCartao, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.LOADING,
    error: null,
  })),
  on(CartaoActions.getCartoesSuccess, (state, { cartoes }) => ({
    ...state,
    data: cartaoAdapter.setAll(cartoes, state.data),
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(CartaoActions.deletarCartaoSuccess, (state, { id }) => ({
    ...state,
    data: cartaoAdapter.removeOne(id, state.data),
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  })),
  on(
    CartaoActions.getCartoesFailure,
    CartaoActions.deletarCartaoFailure,
    (state, { error }) => ({
      ...state,
      status: GenericStoreStatusEnum.ERROR,
      error: error.error.message,
    })
  ),
  on(CartaoActions.setCartaoSelecionado, (state, { cartaoId }) => ({
    ...state,
    cartaoSelecionadoId: cartaoId,
    status: GenericStoreStatusEnum.SUCCESS,
    error: null,
  }))
);

export function reducer(state: CartaoState | undefined, action: Action) {
  return cartaoReducer(state, action);
}
