<div class="grid text-center">
  <h2 class="vip-modal-title">
    Digite o CVV do Cartão
  </h2>
  <h3 class="vip-modal-sub-title">
    Precisamos fazer a confirmação do código de segurança do cartão selecionado.
  </h3>
  <vip-field
    [error]="cvvControl.touched && cvvControl.invalid"
    [message]="{
      mask: 'CVV inválido',
      required: 'Campo obrigatório'
    }"
    [validations]="cvvControl.errors"
    class="fs-mask"
  >
    <input
      id="cc_cvv"
      class="text-center"
      type="tel"
      placeholder="***"
      [formControl]="cvvControl"
      [dropSpecialCharacters]="false"
      mask="000||0000"
    />
  </vip-field>
  <vip-button
    class="mt-4"
    data-cy="btn-confirmar-pagamento"
    (btnClick)="confirm()"
  >
    <span>Confirmar pagamento</span>
  </vip-button>
</div>
