import { ICarrinho, IProdutoCarrinho } from '@vip/core';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ScrollableService } from '@vip/core';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { DialogService } from '@vip/ui/modal';
import { SwiperComponent } from 'swiper/angular';

interface ItemDepartamentoAgrupados {
  departamento: string;
  itens: IProdutoCarrinho[];
}

SwiperCore.use([Navigation]);

@Component({
    selector: 'vip-listagem-carrinho-desktop',
    templateUrl: './listagem-carrinho-desktop.component.html',
    styleUrls: ['./listagem-carrinho-desktop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ListagemCarrinhoDesktopComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  @Input() carrinho?: ICarrinho | null = null;
  @Input() title = 'Produtos';
  @Input() limparProdutos = true;
  @Input() disabled = false;
  @Input() showIconDelete = true;
  @Input() showPrecoUnitario = true;

  @Output() deleteItemClick = new EventEmitter();
  @Output() produtoChanged = new EventEmitter();
  @Output() deleteCarrinhoClick = new EventEmitter<number>();
  @ViewChild('scrollContainer')
  scrollContainer!: ElementRef;
  @ViewChild(SwiperComponent)
  swiper?: SwiperComponent;
  itensAgrupadosPorDepartamento: ItemDepartamentoAgrupados[] = [];
  departamentos: string[] = [];
  selectedIndex = 0;
  swiperHasArrowsButtons = false;
  swiperConfig: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    allowTouchMove: false,
    slidesOffsetAfter: 20,
    slidesOffsetBefore: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  constructor(
    public scrollableService: ScrollableService,
    private dialogService: DialogService
  ) {}

  ngAfterViewChecked(): void {
    if (!this.swiper) return;
    this.swiperHasArrowsButtons = !this.swiper.swiperRef.el.querySelector(
      '.swiper-button-prev.swiper-button-lock'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['carrinho'] && changes['carrinho'].currentValue) {
      this.agrupaItensDepartamentos();
    }
  }

  ngOnInit(): void {
    this.agrupaItensDepartamentos();
  }

  selectedClick(index: number, event: Event) {
    this.selectedIndex = index;
    if (event) {
      event.preventDefault();
    }
    const containerElement = this.scrollContainer.nativeElement;
    const selectedTab = document.getElementById(`departamento_${index}`);
    if (selectedTab) {
      selectedTab.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      containerElement.scrollTop =
        selectedTab.offsetTop - containerElement.offsetTop;
    }
  }

  agrupaItensDepartamentos() {
    if (this.carrinho && this.carrinho?.itens) {
      this.departamentos = Array.from(
        new Set(
          this.carrinho?.itens?.map(
            (item) => item?.departamento?.descricao || 'Combos'
          )
        )
      );

      const agrupadoPorDepartamento: ItemDepartamentoAgrupados[] = [];

      this.carrinho.itens.forEach((item) => {
        const descricaoDepartamento = item?.departamento?.descricao || 'Combos';

        const indiceDepartamento = agrupadoPorDepartamento.findIndex(
          (grupo) => grupo.departamento === descricaoDepartamento
        );

        if (indiceDepartamento !== -1) {
          agrupadoPorDepartamento[indiceDepartamento].itens.push(item);
        } else {
          agrupadoPorDepartamento.push({
            departamento: descricaoDepartamento,
            itens: [item],
          });
        }
      });

      this.itensAgrupadosPorDepartamento = agrupadoPorDepartamento;
    }
  }

  onDeleteCarrinho() {
    const dialog = {
      open: true,
      title: 'Limpar produtos do carrinho',
      subTitle:
        'Tem certeza que deseja remover todos os produtos do seu carrinho?',
      buttonCancelText: 'Cancelar',
      buttonConfirmText: 'Limpar carrinho',
      disabled: false,
    };

    this.dialogService.openDialog(dialog);
    //eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.dialogService.dialogClick.subscribe((event) => {
      if (event) {
        this.deleteCarrinhoClick.emit(this.carrinho?.carrinho_id);
      }
      this.dialogService.clearDialog();
    });
  }

  trackByItensDepartamentos(index: number, item: ItemDepartamentoAgrupados) {
    return `${item.departamento}_${index}`;
  }

  trackByItens(_: number, item: IProdutoCarrinho) {
    return item.id;
  }
}
