import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  Input,
  ContentChildren,
  ChangeDetectorRef,
  QueryList,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CollapseComponent } from '../collapse.component';

@Component({
    selector: 'vip-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('contentExpansion', [
            state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
            state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
            transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
        ]),
    ],
    standalone: false
})
export class AccordionComponent implements AfterViewInit {
  expanded = new Set<number>();
  @Input() collapsing = true;

  @ContentChildren(CollapseComponent) items!: QueryList<CollapseComponent>;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.items.forEach((item, index) => {
      if (item.expanded) {
        this.expanded.add(index);
      }
    });
    this.changeDetector.detectChanges();
  }

  toggleState = (index: number) => {
    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }
  };
}
