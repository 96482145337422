import { DialogService } from '@vip/ui/modal';
import { ICartao, ISelectOptions, MaskPatterns } from '@vip/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
    selector: 'vip-cartao-credito-desktop',
    templateUrl: './cartao-credito-desktop.component.html',
    styleUrls: ['./cartao-credito-desktop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CartaoCreditoDesktopComponent {
  @Input() cartoes?: ICartao[];
  @Input() parcelas: ISelectOptions[] = [];
  @Input() cartaoSelecionadoId?: string | null = null;

  @Output() cadastrarNovoCartao = new EventEmitter<void>();
  @Output() alterarCartaoSelecionado = new EventEmitter<ICartao | null>();
  @Output() deletarCartao = new EventEmitter<string>();
  formGroup: UntypedFormGroup;

  mask = '';
  makeRequestCardNumberAgain = false;
  readonly numeroDigitos = 14;
  readonly masks = {
    cpf: MaskPatterns.CPF,
    data: MaskPatterns.DATA,
  };

  validations = {
    default: {
      required: 'Campo obrigatório',
    },
    cpf_portador: {
      mask: 'CPF inválido',
      required: 'Campo obrigatório',
    },
    cc_number: {
      mask: 'Número de cartão inválido',
      required: 'Campo obrigatório',
      maxlength: 'Informe no máximo 16 digitos',
    },
    cc_expire_date: {
      invalid: 'Data inválida',
      mask: 'Data inválida',
      required: 'Campo obrigatório',
    },
    cc_cvv: {
      mask: 'CVV inválido',
      required: 'Campo obrigatório',
    },
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogService: DialogService
  ) {
    this.formGroup = this.formBuilder.group(
      {
        cc_cvv: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
        cc_expire_date: ['', [Validators.required]],
        cc_expire_month: [''],
        cc_expire_year: [''],
        cpf_portador: ['', [Validators.required]],
        cc_holder: ['', [Validators.required]],
        cc_number: ['', [Validators.required]],
        cc_brand: ['', [Validators.required]],
        criar_token: [false],
      },
      {
        validator: [this.dateValidator('cc_expire_date')],
      }
    );
  }

  handleDeleteCartaoClick(numero: string, $event: Event, cartaoId: string) {
    this.dialogService.openDialog({
      open: true,
      disabled: false,
      title: 'Excluir cartão salvo?',
      subTitle: `O cartão com final ${numero} não poderá mais ser utilizado em compras futuras. Deseja realmente excluir esse cartão?`,
      buttonCancelText: 'Manter cartão',
      buttonConfirmText: 'Excluir cartão',
    });

    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.dialogService.clearDialog().then(() => {
          if (value) this.deletarCartao.emit(cartaoId);
        });
      });

    $event.stopPropagation();
  }

  dateValidator(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const mesInformado = control.value.split('/')[0];
      const anoInformado = control.value.split('/')[1];
      const mesAtual = new Date().getMonth() + 1;
      const anoAtual = new Date().getFullYear();

      if (
        (mesInformado < mesAtual && anoInformado == anoAtual) ||
        anoInformado < anoAtual ||
        mesInformado > 12
      ) {
        control.setErrors({ invalid: true });
      } else {
        if (control.errors && control.errors['invalid']) {
          delete control.errors['invalid'];
          if (Object.keys(control.errors).length === 0) {
            control.setErrors(null);
          }
        }
      }
    };
  }

  aoAlterarCartaoSelecionado(cartaoCofre: ICartao) {
    this.alterarCartaoSelecionado.emit(
      cartaoCofre.id === this.cartaoSelecionadoId ? null : cartaoCofre
    );
  }
}
