<ng-container *ngIf="!isDesktop; else desktopTemplate">
  <div class="flex flex-col h-full">
    <vip-screen-title
      title="Revisão para Entrega"
      [customActions]="true"
      [showActions]="true"
    >
      <vip-button-icon-rounded
        type="outline"
        (btnClick)="voltarClick.emit()"
        class="z-10 pl-1"
      >
        <i class="icon-arrow_back vip-color-secondary-main"></i>
      </vip-button-icon-rounded>
    </vip-screen-title>
    <div class="p-3 flex flex-col flex-grow">
      <div class="flex flex-col flex-grow">
        <vip-card-entrega-retirada
          class="mb-3"
          [tipoEntrega]="tipoEntrega"
          [endereco]="endereco"
          [editable]="!isLojaAutonoma"
          [isLojaAutonoma]="isLojaAutonoma"
          [isRetirada]="isRetirada"
          [dataEntregaFormatada]="horario?.labelMobile"
          [action]="isLojaAutonoma ? 'Retirada' : 'Entrega e Retirada'"
          (alterarEnderecoClick)="handleClickAlterarEndereco()"
          (alterarTipoEntregaClick)="handleClickAlterarTipoEntrega()"
        ></vip-card-entrega-retirada>

        <vip-card-perguntas-sobre-compra
          *ngIf="
            perguntas && perguntas.length > 0 && (horario || isLojaAutonoma)
          "
          [perguntas]="perguntas"
          [button]="buttonLabel"
          [sideButton]="sideButtonLabel"
          (buttonClick)="handleClickPerguntas()"
          (responderPerguntas)="responderPerguntaCompra()"
        ></vip-card-perguntas-sobre-compra>
      </div>
      <vip-card-detalhes-cobranca
        [accordion]="true"
        [valorCompra]="totalCompra"
        [taxaEntrega]="taxaEntrega"
        [valorDescontos]="totalDescontos"
        [descontos]="descontos"
        [taxaServico]="taxaServico"
      ></vip-card-detalhes-cobranca>
    </div>
    <vip-button
      data-cy="realizar-pagamento"
      class="mt-auto mx-3 pb-3"
      [disabled]="
        loading ||
        (!horario && !isLojaAutonoma) ||
        !formPerguntaOneQuestionIsValid ||
        temPerguntaObrigatoriaSemResposta
      "
      (btnClick)="realizarPagamento.emit(temPerguntaObrigatoriaSemResposta)"
    >
      Realizar Pagamento
    </vip-button>
  </div>
</ng-container>
<ng-template #desktopTemplate>
  <div class="flex flex-col mb-2 mt-2 ml-2">
    <vip-breadcrumb
      [breadcrumbs]="breadcrumbs"
      (breadCrumbClick)="breadCrumbClick.emit($event)"
    ></vip-breadcrumb>
    <p class="mt-4 text-lg font-bold vip-color-first-default">Carrinho</p>
  </div>
  <div class="flex justify-between px-2 pb-6">
    <vip-listagem-carrinho-desktop
      class="w-full mr-4"
      [carrinho]="carrinho"
      (deleteItemClick)="deleteItemClick.emit($event)"
      (produtoChanged)="produtoChanged.emit($event)"
      (deleteCarrinhoClick)="deleteCarrinhoClick.emit($event)"
    ></vip-listagem-carrinho-desktop>

    <vip-card-detalhes-cobranca
      class="w-full max-w-[20rem]"
      title="Resumo da Compra"
      confirmButtonText="Finalizar Compra"
      [showIcon]="false"
      [isDesktop]="true"
      [showCashback]="false"
      [valorCompra]="totalCompra"
      [taxaEntrega]="taxaEntrega"
      [valorDescontos]="totalDescontos"
      [descontos]="descontos"
      [taxaServico]="taxaServico"
      [cardBasicBorder]="!isDesktop"
      [showContinuarComprando]="true"
      [showMessageValorMinimo]="showMessageValorMinimo"
      [messageValorMinimo]="messageValorMinimo"
      (continuarComprando)="continuarComprando()"
      [disablePagamentoButton]="disablePagamentoButton"
      (irParaPagamento)="handleClickAlterarEndereco()"
    ></vip-card-detalhes-cobranca>
  </div>
</ng-template>
