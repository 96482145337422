import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IPerguntaSobreCompra, PerguntaTipo } from '@vip/core';

@Component({
    selector: 'vip-card-perguntas-sobre-compra',
    templateUrl: './card-perguntas-sobre-compra.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardPerguntasSobreCompraComponent {
  private _perguntas: IPerguntaSobreCompra[] = [];

  @Input()
  set perguntas(value: IPerguntaSobreCompra[]) {
    this._perguntas = value;

    if (value.length === 1 && value[0].tipo === PerguntaTipo.LISTA) {
      const [perguntaLista] = value;
      this.pergunta = perguntaLista;
      this.formGroup = this.generateFormGroup(perguntaLista);
    }
  }

  get perguntas(): IPerguntaSobreCompra[] {
    return this._perguntas;
  }

  @Input() button = '';
  @Input() sideButton = '';
  @Input() isDesktop?: boolean;
  @Input() perguntasRespondidas?: boolean;
  @Input() resumoFinal?: boolean;
  @Input() isLoading = false;

  @Output() buttonClick = new EventEmitter();
  @Output()
  responderPerguntas = new EventEmitter();

  pergunta?: IPerguntaSobreCompra;
  formGroup?: UntypedFormGroup;

  private generateFormGroup(pergunta: IPerguntaSobreCompra): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    formGroup.addControl(
      pergunta.id.toString(),
      new FormControl(
        pergunta.cliente_pergunta_resposta?.resposta || null,
        pergunta.obrigatoria ? [Validators.required] : []
      )
    );
    return formGroup;
  }
}
