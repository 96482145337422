import { untilDestroyed } from '@ngneat/until-destroy';
import { Router, NavigationEnd } from '@angular/router';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IClassificacaoMercadologica, MenuOptions } from '@vip/core';
import { DropdownDirective } from '@vip/ui/dropdown';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'vip-header-desktop-menu-principal',
  templateUrl: './header-desktop-menu-principal.component.html',
  styleUrls: ['./header-desktop-menu-principal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HeaderDesktopMenuPrincipalComponent implements OnInit {
  @Input() options: MenuOptions = [];
  @Input() departamentos: IClassificacaoMercadologica[] = [];

  @ViewChild(DropdownDirective) dropdown!: DropdownDirective;

  dropdownDepartamentosIsOpen = false;
  activeIndex: number | null = null;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.updateActiveMenu();
      });
    this.updateActiveMenu();
  }

  itemClick(departamento: IClassificacaoMercadologica) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(`/departamentos${departamento.link}`);
      this.dropdown.close();
    });
  }

  getMenuOptionValue(index: number, acessor: string) {
    return this.options[index][acessor];
  }

  onMenuItemClick(menuItem: any, index: number): void {
    const link = this.getMenuOptionValue(index, 'link');

    if (typeof link === 'string') {
      const isInternal = menuItem['internal'] === 'true';
      const isOption = this.options.some((option) => option['link'] === link);

      this.activeIndex = isOption ? index : null;

      this.cdr.detectChanges();

      if (isInternal) {
        this.router.navigate([link]);
      } else {
        window.open(link, '_blank');
      }
    } else {
      this.activeIndex = null;
      this.cdr.detectChanges();
    }
  }

  private updateActiveMenu(): void {
    const currentPath = this.router.url.split('?')[0];

    const foundIndex = this.options.findIndex((option) => {
      const link = option['link'];

      if (typeof link === 'string') {
        return currentPath.includes(link);
      }
      return false;
    });

    this.activeIndex = foundIndex !== -1 ? foundIndex : null;

    this.cdr.detectChanges();
  }
}
