import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  NgZone,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  IColunaPaginas,
  IMAGES_PATH,
  IPaginaInstitucional,
  ISocialLinks,
  LayoutUtilsService,
  IRelacionamentoCliente,
} from '@vip/core';
import { ListaInstitucionalBaseDirective } from '@vip/container/institucional';
import { Router } from '@angular/router';
import { IframeService } from '@vip/native/iframe';

@Component({
    selector: 'vip-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FooterComponent extends ListaInstitucionalBaseDirective {
  @Input()
  textoFooter?: string;
  @Input()
  appleId?: string;
  @Input()
  package?: string;
  @Input()
  participaSupermercadoOnline?: string;
  @Input()
  link_ebit?: string;
  @Input()
  url_google?: string;
  @Input()
  collapsing = false;
  @Input()
  set relacionamentoCliente(value: null | IRelacionamentoCliente) {
    this._relacionamentoCliente = value;
    if (value) {
      const { telefone, whatsapp, email } = value;
      this.relacionamentoClienteViewModel = { telefone, whatsapp, email };
    }
  }
  get relacionamentoCliente(): null | IRelacionamentoCliente {
    return this._relacionamentoCliente;
  }

  colunasPaginas: IColunaPaginas[] = [];
  _redesSociais?: ISocialLinks;
  hasRedesSociais = false;
  relacionamentoClienteViewModel?: Pick<
    IRelacionamentoCliente,
    'email' | 'telefone' | 'whatsapp'
  >;

  private _paginaInstitucionais: IPaginaInstitucional[] = [];
  private _relacionamentoCliente: IRelacionamentoCliente | null = null;
  readonly ICONE_CONTATO: Record<
    string,
    'icon-phone' | 'icon-whatsapp' | 'icon-mail'
  > = {
    whatsapp: 'icon-whatsapp',
    email: 'icon-mail',
    telefone: 'icon-phone',
  };

  constructor(
    public sanitizer: DomSanitizer,
    @Inject(IMAGES_PATH) public imagesPath: string,
    private layoutUtilsService: LayoutUtilsService,
    protected override route: Router,
    protected override iframeService: IframeService,
    protected override ngZone: NgZone
  ) {
    super(route, iframeService, ngZone);
  }

  @Input()
  set redesSociais(redesSociais: ISocialLinks | undefined) {
    this.hasRedesSociais = this.checkRedesSociais(redesSociais);
    this._redesSociais = redesSociais;
  }

  get redesSociais(): ISocialLinks | undefined {
    return this._redesSociais;
  }

  @Input()
  set paginaInstitucionais(paginaInstitucionais: IPaginaInstitucional[]) {
    this._paginaInstitucionais = paginaInstitucionais;
    this.setColunasPaginas(paginaInstitucionais);
  }

  get paginaInstitucionais(): IPaginaInstitucional[] {
    return this._paginaInstitucionais;
  }

  get isDesktop(): boolean {
    return this.layoutUtilsService.isDesktop();
  }

  get isMobile(): boolean {
    return this.layoutUtilsService.isMobile();
  }

  get isRelacionamentoEmpty(): boolean {
    if (!this.relacionamentoClienteViewModel) {
      return true;
    }
    const { email, telefone, whatsapp } = this.relacionamentoClienteViewModel;
    return email === '' && telefone === '' && whatsapp === '';
  }

  shouldShowAccordion(isDesktop: boolean): boolean {
    return !isDesktop;
  }

  setColunasPaginas(paginaInstitucionais: IPaginaInstitucional[]) {
    this.colunasPaginas = [];
    this.colunasPaginas.push({
      titulo: 'Quem somos',
      paginas: paginaInstitucionais.filter(
        (pagina) => pagina.slug === 'quem-somos'
      ),
    });

    this.colunasPaginas.push({
      titulo: 'Forma de Pagamento',
      paginas: paginaInstitucionais.filter(
        (pagina) => pagina.slug === 'formas-de-pagamento'
      ),
    });

    this.colunasPaginas.push({
      titulo: 'Institucional',
      paginas: [...paginaInstitucionais],
    });
  }

  checkRedesSociais(redesSociais: ISocialLinks | undefined) {
    if (!redesSociais) return false;
    return !Object.values(redesSociais).every((x) => x === null || x === '');
  }

  handleClickContato(key: string): void {
    if (!this.relacionamentoCliente) return;

    let url = '';
    const { email, whatsapp, mensagem_whatsapp, telefone } =
      this.relacionamentoCliente;

    switch (key) {
      case 'email':
        url = `mailto:${email}`;
        break;
      case 'telefone':
        url = `tel:${telefone.replace(/[^0-9]/g, '')}`;
        break;
      case 'whatsapp':
        url = `https://wa.me/55${whatsapp.replace(
          /[^0-9]/g,
          ''
        )}?text=${mensagem_whatsapp.replace(/ /g, '%20')}`;
        break;
    }
    if (url && (this.isMobile || key === 'whatsapp')) {
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      window.open(
        this.sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          safeUrl
        ) as string,
        '_blank'
      );
    }
  }
}
