import { APP_ID, NgModule, inject, provideAppInitializer } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import { ApiModule, LojaApiService, LojaTokenService } from '@vip/api';
import { StoreModule } from '@ngrx/store';
import { environment, CoreModule, STORAGE, PROVIDERS_SITE } from '@vip/core';
import {
  FilialStateModule,
  FilialFacade,
  FilialEmManutencaoGuard,
  FilialSemEcommerceGuard,
} from '@vip/state/filial';
import {
  ClienteStateModule,
  ClienteFacade,
  CadastroIncompletoGuard,
} from '@vip/state/cliente';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import { StartupService } from './startup-service/startup.service';
import localePt from '@angular/common/locales/pt';
import { MessageService } from '@vip/ui/message';
import { AplicativoFacade, AplicativoStateModule } from '@vip/state/aplicativo';
import { SessaoFacade, SessaoStateModule } from '@vip/state/sessao';
import { CarrinhoStateModule } from '@vip/state/carrinho';
import { CarrinhoItensStateModule } from '@vip/state/carrinho-itens';
import {
  CentroDistribuicaoStateModule,
  SelecaoCdGuard,
} from '@vip/state/centro-distribuicao';
import {
  TipoEntregaStateModule,
  ValidaHorarioSelecionadoGuard,
} from '@vip/state/tipo-entrega';
import { EffectsModule } from '@ngrx/effects';
import {
  RECAPTCHA_SETTINGS,
  RECAPTCHA_LANGUAGE,
  RecaptchaLoaderService,
} from 'ng-recaptcha-2';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReactiveFormsModule } from '@angular/forms';
import { CepStateModule } from '@vip/state/cep';
import { ValidarCepContainerModule } from '@vip/container/validar-cep';
import { EnderecoStateModule } from '@vip/state/endereco';
import { CompraEmProcessoStateModule } from '@vip/state/compra-em-processo';
import {
  authInterceptorProvider,
  limparStatesOnLogoutMetaReducer,
  transferStateInterceptorProvider,
} from '@vip/state/auth';
import { ButtonModule } from '@vip/ui/button';
import { ModalModule } from '@vip/ui/modal';
import { StorageSiteService } from './storage-service/storage.service';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AddToListContainerModule } from '@vip/container/add-to-list';
import { AuthGuard, ExigeAutenticacaoGuard } from '@vip/state/auth';
import { AppUpdateService } from '@vip/native/app-update';
import { appModuleRoutes } from './app.module.routes';
import {
  HomeOmniGuard,
  HomeOmnichannelStateModule,
} from '@vip/state/home-omnichannel';
import { SelecaoCdStateModule } from '@vip/state/selecao-cd';
import { LayoutDesktopContainerModule } from '@vip/container/layout-desktop';
import { CodeScannerService } from '@vip/native/code-scanner';
import { ConfirmacaoSaidaPagamentoGuard } from '@vip/container/pagamento';
import { ManutencaoGuard } from '@vip/container/manutencao';
import { SiteLojaGuard } from '@vip/container/home-ecommerce';
import { OfertaExclusivaGuard } from '@vip/container/ofertas';
import { CompletarCadastroGuard } from '@vip/container/modal-completar-cadastro';
import {
  GoogleAnalyticsInitializer,
  GtagFn,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN,
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { ProdutoStateModule } from '@vip/state/produto';
import { FirebaseService } from '@vip/native/firebase';

registerLocaleData(localePt, 'pt');
export function initializeApp(startupService: StartupService) {
  return startupService.asyncStartup();
}

export async function initGA(
  startupService: StartupService,
  gtag: GtagFn,
  doc: Document
): Promise<void> {
  startupService.gaTag$.subscribe(async (gaTag) => {
    if (gaTag === null || gaTag === undefined || gaTag === '') {
      return;
    }

    await GoogleAnalyticsInitializer({ trackingCode: gaTag }, gtag, doc)();
  });
}
@NgModule({
  declarations: [AppComponent, HomeComponent],
  bootstrap: [AppComponent],
  imports: [
    ReactiveFormsModule,
    CoreModule,
    ButtonModule,
    ModalModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appModuleRoutes, {
      scrollPositionRestoration: 'top',
      useHash: false,
      initialNavigation: 'enabledBlocking',
    }),
    ApiModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [limparStatesOnLogoutMetaReducer]
          : [limparStatesOnLogoutMetaReducer],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    StoreRouterConnectingModule.forRoot(),
    ClienteStateModule,
    CarrinhoStateModule,
    CarrinhoItensStateModule,
    CentroDistribuicaoStateModule,
    CepStateModule,
    AddToListContainerModule,
    ValidarCepContainerModule,
    EnderecoStateModule,
    TipoEntregaStateModule,
    FilialStateModule,
    AplicativoStateModule,
    SessaoStateModule,
    HomeOmnichannelStateModule,
    CompraEmProcessoStateModule,
    SelecaoCdStateModule,
    LayoutDesktopContainerModule,
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
    ProdutoStateModule,
  ],
  providers: [
    ...PROVIDERS_SITE,
    { provide: APP_ID, useValue: 'serverApp' },
    provideAppInitializer(() => {
      const initializerFn = initializeApp(inject(StartupService));
      return initializerFn();
    }),
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6Led2LUUAAAAAEp94Cec3sUMtDAMbvgkUXJxBKrg' },
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'pt',
    },
    {
      provide: STORAGE,
      useClass: StorageSiteService,
    },
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: initGA,
      deps: [StartupService, NGX_GTAG_FN, DOCUMENT],
      multi: true,
    },
    MessageService,
    AuthGuard,
    ExigeAutenticacaoGuard,
    ConfirmacaoSaidaPagamentoGuard,
    SelecaoCdGuard,
    HomeOmniGuard,
    OfertaExclusivaGuard,
    FilialEmManutencaoGuard,
    FilialSemEcommerceGuard,
    ManutencaoGuard,
    authInterceptorProvider,
    transferStateInterceptorProvider,
    AppUpdateService,
    CadastroIncompletoGuard,
    CodeScannerService,
    ConfirmacaoSaidaPagamentoGuard,
    RecaptchaLoaderService,
    SiteLojaGuard,
    CompletarCadastroGuard,
    ValidaHorarioSelecionadoGuard,
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
  ],
})
export class AppModule {}
