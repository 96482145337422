import { Input, ContentChild, Component } from '@angular/core';
import { CollapseContentDirective } from './collapse-content.directive';
import { CollapseHeaderDirective } from './collapse-header.directive';

@Component({
    selector: 'vip-collapse',
    template: ``,
    standalone: false
})
export class CollapseComponent {
  @Input() disabled = false;
  @Input() expanded = false;
  @ContentChild(CollapseContentDirective) content!: CollapseContentDirective;
  @ContentChild(CollapseHeaderDirective) header!: CollapseHeaderDirective;
}
