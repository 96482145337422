import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LojaTokenService } from '@vip/api';
import { ClienteFacade, ClienteTokenService } from '@vip/state/cliente';
import { SessaoService } from '@vip/state/sessao';
import { DialogService } from '@vip/ui/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutUtilsService } from '@vip/core';

@UntilDestroy()
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  dialogOpened = false;

  constructor(
    private clienteTokenService: ClienteTokenService,
    private clienteFacade: ClienteFacade,
    private lojaTokenService: LojaTokenService,
    private sessaoService: SessaoService,
    private dialogService: DialogService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.clienteTokenService.getToken();
    const sessaoId = this.sessaoService.getSessaoId();
    let newHeader = request.headers;

    newHeader = newHeader.set('sessao-id', sessaoId);
    const antigoToken = newHeader.get('Authorization');

    if (token) {
      if (!antigoToken)
        newHeader = newHeader.set(`Authorization`, `Bearer ${token}`);
      return next
        .handle(
          request.clone({
            headers: newHeader,
          })
        )
        .pipe(
          tap({
            error: (error: HttpErrorResponse) => {
              if (error.status === 401) {
                if (
                  !!error?.error?.message &&
                  this.isLimiteTentativasPagamentos(error.error.message)
                ) {
                  this.showDialog(error.error.message);
                } else {
                  this.clienteFacade.logout();
                }
              }
            },
          })
        );
    }
    const tokenLoja = this.lojaTokenService.getToken();
    if (!antigoToken)
      newHeader = newHeader.set(`Authorization`, `Bearer ${tokenLoja}`);

    return next.handle(
      request.clone({
        headers: newHeader,
      })
    );
  }

  private isLimiteTentativasPagamentos(msg: string) {
    return msg.includes(
      'Por favor, utilize uma forma de pagamento na entrega ou entre em contato com o nosso atendimento.'
    );
  }

  private showDialog(msg: string) {
    if (this.dialogOpened) return;

    this.dialogService.openDialog(
      {
        open: true,
        title: 'Você excedeu o limite de tentativas para pagamento online',
        subTitle: msg,
        disabled: false,
        buttonConfirmText: 'Pagar na entrega',
        buttonCancelText: 'Contatar suporte',
      },
      true
    );
    this.dialogOpened = true;

    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((confirm) => {
        this.dialogOpened = false;
        this.dialogService.clearDialog();
        this.clienteFacade.logout(
          confirm
            ? '/login'
            : this.layoutUtilsService.isDesktop()
            ? '/institucional/fale-conosco'
            : '/fale-conosco'
        );
      });
  }
}

export const authInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
