<header
  class="header-sub-menu-mobile"
  [ngClass]="{ scrolling: scrollingShadow }"
  role="heading"
>
  <section>
    <vip-button
      aria-label="botao menu"
      role="button"
      [hasIcon]="true"
      [noPadding]="true"
      size="medium"
      type="normal"
      class="vip-menu-btn"
      (click)="handleMenuClick()"
    >
      <i class="vip-icon icon-menu vip-button-no-margin-right"></i>
    </vip-button>
    <vip-image
      *ngIf="logoSrc"
      class="vip-logo-mobile cursor-pointer"
      [class.is-televendas]="isTelevendas"
      [imageUrl]="logoSrc"
      [fallbackImageUrl]="fallbackImageUrl"
      [hiddenOnError]="true"
      (click)="goToHome()"
      role="img"
      alt="Logo do {{ isTelevendas ? 'Televendas' : 'Mercado' }}"
      ariaLabel="logo principal do {{
        isTelevendas ? 'televendas' : 'mercado'
      }}"
      data-cy="logo-mobile"
    ></vip-image>
    <vip-button
      aria-label="botao carrinho"
      role="button"
      [hasIcon]="true"
      [noPadding]="true"
      type="normal"
      size="medium"
      class="mr-1 vip-carrinho-mobile-btn"
      [ngClass]="{
        'ml-1': !isTelevendasSemCliente,
        'ml-3': isTelevendasSemCliente
      }"
      (btnClick)="handleResumeClick()"
    >
      <vip-badge
        *ngIf="qtdItensCarrinho && qtdItensCarrinho > 0"
        [number]="qtdItensCarrinho.toString() || '0'"
        position="end"
      ></vip-badge>
      <i class="vip-icon icon-shopping_cart vip-button-no-margin-right"></i>
    </vip-button>
  </section>

  <vip-search
    class="pt-2 mr-3 ml-3"
    aria-label="botao de pesquisa"
    placeholder="O que você precisa?"
    [value]="searchValue"
    (search)="handleQuickSearchEvent($event)"
    (click)="handleSearchClick($event)"
  ></vip-search>
</header>

<div
  id="dropdown-entrega"
  vipDropdown
  aria-label="Informaçao de entrega"
  class="vip-dropdown-container"
  [ngClass]="{ 'background-submenu-responsive': !isApp }"
  [dontTranslate]="true"
  [disableDropdown]="disableEntregaRetirada"
  (openChange)="dropdownEntregaRetiradaOpen = $event"
>
  <vip-button
    vipDropdownButton
    type="normal"
    size="medium"
    class="vip-entrega-retirada-mobile-btn"
    [ariaExpanded]="dropdownEntregaRetiradaOpen"
    [ariaHaspopup]="true"
    [disabled]="disableEntregaRetirada"
  >
    <i class="vip-icon {{ iconEntregaRetirada }}"></i>
    <div
      class="vip-entrega-retirada-wrapper flex flex-col text-start whitespace-nowrap items-baseline"
      [ngClass]="{
        'color-text-desktop': !isApp,
        'vip-color-first-default': isApp
      }"
    >
      <ng-container *ngIf="enderecoSelecionado; else noAddressTemplate">
        <h2 class="text-tiny">{{ labelEndereco }} no endereço:</h2>
        <div class="vip-endereco-wrapper">
          <p class="text-xs font-bold inline">
            {{ enderecoSelecionado.endereco_formatado }}
          </p>
        </div>
      </ng-container>
      <ng-template #noAddressTemplate>
        <h2 class="text-xs">Entrega ou Retirada</h2>
      </ng-template>
    </div>
    <i
      *ngIf="!disableEntregaRetirada"
      class="vip-icon icon-chevron_right icone-expand_more"
      [class.expanded]="dropdownEntregaRetiradaOpen"
    ></i>
  </vip-button>
  <vip-entrega-retirada
    class="vip-dropdown-content entrega-retirada"
    vipDropdownContent
    [enderecos]="enderecos"
    [cdsEntrega]="cdsEntrega"
    [cdsRetirada]="cdsRetirada"
    [realizaEntrega]="realizaEntrega"
    [enderecoSelecionado]="enderecoSelecionado"
    [cdSelecionado]="cdSelecionado || null"
    [clienteLogado]="!!cliente"
    [possuiLojaAutonoma]="possuiLojaAutonoma"
    [isLojaAutonoma]="isLojaAutonoma"
    [isFromResumoCompra]="isFromResumoCompra"
    [isMobile]="false"
    (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
    (cdChange)="cdChange.emit($event)"
    (entregaChange)="entregaChange.emit($event)"
    (cadastrarEndereco)="cadastrarEndereco.emit($event)"
    (informarCepClick)="informarCepClick.emit()"
    (formaEntregaChange)="formaEntregaChange.emit($event)"
    (alterarEndereco)="alterarEndereco.emit($event)"
  ></vip-entrega-retirada>
</div>
