import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ISelectOptions } from '@vip/core';
import { SelectEvent } from '@vip/ui/select';

@Component({
    selector: 'vip-modal-numero-parcelas',
    templateUrl: './modal-numero-parcelas.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ModalNumeroParcelasComponent {
  @Input()
  parcelas: ISelectOptions[] = [];

  @Input()
  parcelaSelecionada?: string;

  @Output()
  selecionarParcela = new EventEmitter<ISelectOptions>();

  installmentsControl = new UntypedFormControl('', [Validators.required]);
  private parcelaSelecionadaOption?: ISelectOptions;

  selectParcela(selectEvent: SelectEvent): void {
    this.parcelaSelecionadaOption = this.parcelas.find(
      (parcela) => parcela.value == selectEvent.value
    );
  }

  submit(): void {
    if (this.installmentsControl.invalid && !this.parcelaSelecionada) {
      this.installmentsControl.markAsTouched();
      return;
    }
    this.selecionarParcela.emit(this.parcelaSelecionadaOption);
  }
}
