import { NgxMaskPipe } from 'ngx-mask';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ICliente,
  IFilialTelevendasSelect,
  ITelevendas,
  MaskPatterns,
} from '@vip/core';
import { SearchComponent, SearchEvent } from '@vip/ui/search';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectEvent } from '@vip/ui/select';

@Component({
    selector: 'vip-selecionar-cliente-televendas',
    templateUrl: './selecionar-cliente-televendas.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelecionarClienteTelevendasComponent implements OnInit, OnChanges {
  @ViewChild(SearchComponent, { static: false }) searchInput:
    | SearchComponent
    | undefined;

  openModalCepEmitter = new EventEmitter();
  @Input() isDesktopResponsive = false;

  @Input()
  set filiaisTelevendas(value: ITelevendas | null) {
    this._filiaisTelevenda = value ?? null;
    this.cd.detectChanges();
  }

  get filiaisTelevendas(): ITelevendas | null {
    return this._filiaisTelevenda;
  }

  @Input()
  set clientesTelevenda(value: ICliente[] | null) {
    this._clientesTelevenda = value ?? [];
  }

  get clientesTelevenda(): ICliente[] | null {
    return this._clientesTelevenda;
  }

  @Input() loadingTelevendas = false;
  @Input() isTelevendas = false;

  @Output() openDesktopCepModal = new EventEmitter();
  @Output() searchClickTelevendas = new EventEmitter();
  @Output() itemSelected = new EventEmitter();
  @Output() selectedFilial = new EventEmitter();

  isDesktopResponsiveRef = false;
  filiais: IFilialTelevendasSelect[] = [];
  private searchValue = '';
  private readonly destroy$ = new Subject<void>();
  _clientesTelevenda: ICliente[] | null = [];
  _filiaisTelevenda: ITelevendas | null = null;

  public readonly masks = {
    cpf: MaskPatterns.CPF,
    cnpj: MaskPatterns.CNPJ,
    celular: MaskPatterns.CELULAR,
    email: '',
  };

  public currentInputType: 'cpf' | 'cnpj' | 'celular' | 'email' = 'email';
  public mask = '';
  public previousSearchValue = '';

  formGroup = new UntypedFormGroup({
    filial: new UntypedFormControl(null, [Validators.required]),
    cliente: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private cd: ChangeDetectorRef, public NgxMaskPipe: NgxMaskPipe) {}

  ngOnInit(): void {
    this.isDesktopResponsiveRef = this.isDesktopResponsive;

    if (!this.isDesktopResponsiveRef) {
      if (Array.isArray(this._filiaisTelevenda?.filiais)) {
        this.filiais =
          this._filiaisTelevenda?.filiais.map((item) => ({
            value: {
              vipcommerce_filial_id: item?.vipcommerce_filial_id.toString(),
              filial_id: item?.id,
            },
            text: item.nome ?? '',
          })) ?? [];
      } else {
        this.filiais = [];
      }
    }
  }

  ngOnChanges(): void {
    if (this.searchInput) {
      this.formGroup
        .get('cliente')
        ?.setValue(this.searchInput.input?.nativeElement.value);
    }

    if (Array.isArray(this._filiaisTelevenda?.filiais)) {
      this.filiais =
        this._filiaisTelevenda?.filiais.map((item) => ({
          value: {
            vipcommerce_filial_id: item?.vipcommerce_filial_id?.toString(),
            filial_id: item?.id,
          },
          text: item?.nome ?? '',
        })) ?? [];
    } else {
      this.filiais = [];
    }

    this.formGroup
      .get('cliente')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value.length < 3) {
          this.formGroup.get('cliente')?.setErrors({ minlength: true });
        } else {
          this.formGroup.get('cliente')?.setErrors(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openModalCep() {
    this.isDesktopResponsive
      ? this.openDesktopCepModal.emit()
      : this.openModalCepEmitter.emit();
  }

  public handleSearchEvent(event: SearchEvent) {
    event.stopPropagation();
    this.searchValue = event.value;

    if (this.searchInput) {
      const inputElement = this.searchInput.input?.nativeElement;

      if (inputElement) {
        const inputValue = inputElement.value || '';

        const cursorPosition = inputElement.selectionStart;

        inputElement.value = inputValue;

        inputElement.setSelectionRange(cursorPosition, cursorPosition);

        if (/^\d/.test(inputValue)) {
          let numericValue = inputValue.replace(/[-./]/g, '');

          if (
            this.currentInputType === 'cpf' ||
            this.currentInputType === 'cnpj'
          ) {
            numericValue = numericValue.substring(0, 14);
          }

          const maskedValue = this.NgxMaskPipe.transform(
            numericValue,
            this.mask
          );

          const limitedSearchValue = numericValue.substring(0, 14);

          if (limitedSearchValue.length >= 3) {
            this.searchClickTelevendas.emit(
              new SearchEvent('keyup', limitedSearchValue, event)
            );
          }
        } else {
          if (inputValue.length >= 3) {
            this.searchClickTelevendas.emit(
              new SearchEvent('keyup', inputValue, event)
            );
          }
        }
      }
    }
  }

  handleSearchClick(event: Event) {
    event.stopPropagation();
    const inputValue = this.formGroup.get('cliente')?.value;

    if (inputValue && inputValue.length >= 3) {
      const newEvent = new SearchEvent('click', inputValue, event);
      this.searchClickTelevendas.emit(newEvent);
    }
  }

  selectedItem(event: ICliente) {
    if (event) {
      this.itemSelected.emit(event);
      this.resetFormCliente();
    }
  }

  selectedFilialItem(event: SelectEvent) {
    this.selectedFilial.emit(event);
    if (event.text === '(Selecione)') {
      this.resetFormCliente();
    }
  }

  resetFormCliente() {
    if (this.searchInput) {
      this.searchInput.clear();
    }
    this._clientesTelevenda = null;
  }

  public handleUserKeyUp(event: KeyboardEvent): void {
    if (
      this.searchInput &&
      this.searchInput.input &&
      this.searchInput.input.nativeElement
    ) {
      const inputElement = this.searchInput.input.nativeElement;
      let inputValue = inputElement.value || '';

      if (!inputValue) return;

      const startsWithLetter = /^[a-zA-Z]/.test(inputValue);

      if (!startsWithLetter && this.currentInputType !== 'email') {
        inputValue = inputValue.replace(/[^\d]/g, '');
      }

      const onlyDigits = inputValue.match(/^\d+$/g);

      if (onlyDigits) {
        if (inputValue.length >= 11) {
          this.currentInputType = this.isValidCPF(inputValue)
            ? 'cpf'
            : 'celular';
        } else {
          this.currentInputType = 'cpf';
        }
      } else {
        this.currentInputType = 'email';
      }

      this.mask = this.masks[this.currentInputType];

      const maskedValue = startsWithLetter
        ? inputValue
        : this.mask
        ? this.NgxMaskPipe.transform(inputValue, this.mask)
        : inputValue;

      inputElement.value = maskedValue;
      const cursorPosition = maskedValue.length;
      inputElement.setSelectionRange(cursorPosition, cursorPosition);

      this.handleSearchEvent(new SearchEvent('keyup', inputValue, event));
    }
  }

  private isValidCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0,
      remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }
}
