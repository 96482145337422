<div class="px-6 pt-4 pb-4">
  <h5 class="text-xl vip-color-primary-main font-bold">Erro de comunicação</h5>
  <p class="font-normal pt-2 text-[16px] vip-color-second-default">
    Estamos enfrentando dificuldades para nos comunicar com nossos servidores no
    momento. Por favor, verifique sua conexão com a internet e tente novamente.
    Se o problema persistir, entre em contato com o nosso suporte ou tente
    novamente mais tarde.
  </p>
</div>
<div class="vip-background-complementary w-full flex items-center justify-end">
  <div class="pr-6 py-3 w-full flex justify-end">
    <div class="flex gap-2">
      <div class="w-[160px]">
        <vip-button
          data-cy="btn-confirma-cancelar-compra"
          type="outline"
          [bgOutlineNone]="true"
          (click)="checkSupportButtonState()"
          >Contatar suporte
        </vip-button>
      </div>
      <div class="w-[160px]">
        <vip-button
          data-cy="btn-confirma-cancelar-compra"
          [size]="'medium'"
          (click)="checkConfirmButtonState()"
          >Tentar novamente
        </vip-button>
      </div>
    </div>
  </div>
</div>
