import { Pipe, PipeTransform } from '@angular/core';
import { FormaPagamentoEnum, IFormaPagamento } from '@vip/core';

@Pipe({
    name: 'textConfirmButtonFormaPagamento',
    standalone: false
})
export class TextConfirmButtonFormaPagamentoPipe implements PipeTransform {
  private readonly FORMA_PAGAMENTO_ENUM = FormaPagamentoEnum;

  transform(
    formaPagamentoSelecionada: IFormaPagamento | null | undefined,
    permitirSalvarCartao: boolean,
    possuiCartoesSalvos: boolean,
    cartaoSalvoSelecionado: boolean
  ): string {
    let result = 'Confirmar';
    const formaPagamentoIsPreSemCarteiraDigital =
      formaPagamentoSelecionada?.pagamento ===
        this.FORMA_PAGAMENTO_ENUM.PRE_PAGO &&
      !formaPagamentoSelecionada?.carteira_digital;
    const possuiCartoesENaoSelecionou =
      possuiCartoesSalvos &&
      !formaPagamentoSelecionada &&
      !cartaoSalvoSelecionado;

    if (
      permitirSalvarCartao &&
      (formaPagamentoIsPreSemCarteiraDigital || possuiCartoesENaoSelecionou)
    ) {
      result = 'Adicionar novo cartão';
    }

    if (cartaoSalvoSelecionado) {
      result = 'Confirmar';
    }

    return result;
  }
}
