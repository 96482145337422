import { LOCALE_ID, NgModule } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from '@angular/common';
import { PagamentoComponent } from './components/pagamento/pagamento.component';
import localePt from '@angular/common/locales/pt';
import { FormaPagamentoComponent } from './components/forma-pagamento/forma-pagamento.component';
import { TabsModule } from '@vip/ui/tabs';
import { CardPagamentoModule } from '@vip/ui/card-pagamento';
import { CardFormaPagamentoModule } from '@vip/ui/card-forma-pagamento';
import { ScreenTitleModule } from '@vip/ui/screen-title';
import { ButtonModule } from '@vip/ui/button';
import { CardCuponsCompraModule } from '@vip/ui/card-cupons-compra';
import { EmptyModule } from '@vip/ui/empty';
import { CupomModule } from '@vip/ui/cupom';
import { PagamentoCuponsComponent } from './components/pagamento-cupons/pagamento-cupons.component';
import { FieldModule } from '@vip/ui/field';
import { MessageService } from '@vip/ui/message';
import { CardOpcaoFaturamentoModule } from '@vip/views/card-opcao-faturamento';
import { PagamentoCartaoComponent } from './components/pagamento-cartao/pagamento-cartao.component';
import { PagamentoCarteiraDigitalComponent } from './components/pagamento-carteira-digital/pagamento-carteira-digital.component';
import { CardDetalhesCobrancaModule } from '@vip/ui/card-detalhes-cobranca';
import { CardResumoProdutosModule } from '@vip/ui/card-resumo-produtos';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskPipe, NgxMaskModule } from 'ngx-mask';
import { SelectModule } from '@vip/ui/select';
import { ImageModule } from '@vip/ui/image';
import { CardCashbackModule } from '@vip/ui/card-cashback';
import { CountdownBarModule } from '@vip/ui/countdown-bar';
import { SwitchModule } from '@vip/ui/switch';
import { CheckboxModule } from '@vip/ui/checkbox';
import { ModalModule } from '@vip/ui/modal';
import { ModalNumeroParcelasComponent } from './components/modal-numero-parcelas/modal-numero-parcelas.component';
import { SelectableCardModule } from '@vip/ui/selectable-card';
import { CoreModule, PagamentoTextoPipe } from '@vip/core';
import { ModalCvvComponent } from './components/modal-cvv/modal-cvv.component';
import { ModalCaptchaComponent } from './components/modal-captcha/modal-captcha.component';
import { TextConfirmButtonFormaPagamentoPipe } from './pipes/text-confirm-button-forma-pagamento.pipe';
import { CardTipoPagamentoModule } from '@vip/ui/card-tipo-pagamento';
import {
  RecaptchaFormsModule,
  RecaptchaLoaderService,
  RecaptchaModule,
} from 'ng-recaptcha-2';
import { LoadingSpinnerModule } from '@vip/ui/loading-spinner';
import { PagamentoCarteiraDigitalDesktopComponent } from './components/pagamento-carteira-digital-desktop/pagamento-carteira-digital-desktop.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    CardPagamentoModule,
    ScreenTitleModule,
    ButtonModule,
    CardFormaPagamentoModule,
    CardCuponsCompraModule,
    EmptyModule,
    CupomModule,
    FieldModule,
    CardTipoPagamentoModule,
    CardOpcaoFaturamentoModule,
    CardDetalhesCobrancaModule,
    CardResumoProdutosModule,
    CountdownBarModule,
    ImageModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    SelectModule,
    CardCashbackModule,
    SwitchModule,
    CheckboxModule,
    ModalModule,
    SelectableCardModule,
    CoreModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    LoadingSpinnerModule,
  ],
  declarations: [
    PagamentoComponent,
    FormaPagamentoComponent,
    PagamentoCuponsComponent,
    PagamentoCartaoComponent,
    PagamentoCarteiraDigitalComponent,
    PagamentoCarteiraDigitalDesktopComponent,
    ModalNumeroParcelasComponent,
    ModalCvvComponent,
    ModalCaptchaComponent,
    TextConfirmButtonFormaPagamentoPipe,
  ],
  exports: [
    PagamentoComponent,
    FormaPagamentoComponent,
    PagamentoCuponsComponent,
    PagamentoCartaoComponent,
    PagamentoCarteiraDigitalComponent,
    PagamentoCarteiraDigitalDesktopComponent,
    ModalNumeroParcelasComponent,
    ModalCvvComponent,
    ModalCaptchaComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    CurrencyPipe,
    MessageService,
    PagamentoTextoPipe,
    RecaptchaLoaderService,
    NgxMaskPipe,
  ],
})
export class PagamentoModule {}
