import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IProduto } from '@vip/core';
import { ISpinEventComProduto } from '@vip/ui/spin';

@Component({
    selector: 'vip-search-menu',
    templateUrl: './search-menu.component.html',
    styleUrls: ['./search-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchMenuComponent implements OnChanges {
  @Input()
  products: IProduto[] | null = [];

  @Input()
  suggestions: string[] | null = [];

  @Input()
  opened = false;

  @Input() termoPesquisado = '';

  @Input() produtosNoCarrinho?: Map<
    number,
    {
      item_id: number;
      quantidade: number;
      observacao?: string;
      seletor_medida_id?: number | null | undefined;
    }
  > | null = null;

  @Input()
  isLogged = false;

  @Input()
  isLoading = false;

  @Output()
  searchByTermClick = new EventEmitter<string>();

  @Output()
  productClick = new EventEmitter<IProduto>();

  @Output()
  spinChanged = new EventEmitter<ISpinEventComProduto>();

  @Output()
  tagClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  openedConfirmDeleteModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  formProcuraProduto: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() visible = new EventEmitter<IProduto>();
  onVisible(produto: IProduto) {
    this.visible.emit(produto);
  }
  get noResults(): boolean {
    return !this.products || this.products.length <= 0;
  }

  get noSuggestions(): boolean {
    return !this.suggestions || this.suggestions.length <= 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateProductsWithCartInfo();
  }

  trackProductFn(index: number, product: IProduto): number {
    return product.id;
  }

  handleSuggestionKeyboardEntry(event: KeyboardEvent, termo: string): void {
    if (event.key === 'Enter') this.searchByTermClick.emit(termo);
  }

  updateProductsWithCartInfo() {
    if (this.produtosNoCarrinho != null && this.products) {
      this.products.forEach((product) => {
        const cartItem = this.produtosNoCarrinho?.get(product.produto_id);
        if (cartItem && cartItem.seletor_medida_id != null) {
          product.seletor_medida_id = cartItem.seletor_medida_id;
        }
      });
    }
  }
}
