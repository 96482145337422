<div *ngIf="formaPagamentos" class="flex flex-col flex-grow">
  <vip-screen-title
    title="Formas de Pagamento"
    [customActions]="true"
    [showActions]="true"
  >
    <vip-button-icon-rounded
      type="outline"
      class="z-30 pl-1"
      data-cy="btn-voltar"
      (btnClick)="goBack()"
    >
      <i class="vip-color-secondary-main icon-arrow_back"></i>
    </vip-button-icon-rounded>
  </vip-screen-title>

  <section class="flex flex-col flex-grow mt-3 px-3">
    <vip-switch
      *ngIf="showSwitch"
      class="block mb-3"
      [firstOption]="pagamentoOptions.online"
      [secondOption]="pagamentoOptions.entrega"
      [optionChecked]="pagamentoOptions.checked"
      (changedItemActive)="pagamentoOptions.checked = $event"
    ></vip-switch>
    <ng-container [ngSwitch]="pagamentoOptions.checked.key">
      <ng-container *ngSwitchCase="'online'">
        <ng-container
          *ngIf="
            !isNewCard() && cartoes.length > 0 && permitirSalvarCartao;
            else formasPagamentoDefaultTemplate
          "
        >
          <span class="font-bold text-md vip-color-first-default mb-3 block">
            Cartões
          </span>
          <ng-container
            *ngFor="let cartao of cartoesExibidos; let isLast = last"
          >
            <vip-selectable-card
              data-cy="vip-card-cartao-salvo"
              [class.pb-2]="!(cartoes.length > 3 && isLast)"
              [showIcon]="false"
              [fullWidth]="true"
              [checked]="cartao.id === cartaoSelecionadoId"
              (changed)="
                $event ? selectCartaoSalvo(cartao) : removerDadosCartaoSalvo()
              "
            >
              <div title class="flex items-center">
                <vip-image
                  class="vip-bandeira-cartao-salvo mr-3"
                  [alt]="cartao.bandeira"
                  [imageUrl]="cartao.logomarca || ''"
                  classNames="vip-card-forma-pagamento-image-size"
                ></vip-image>
                <div
                  class="flex flex-row items-center w-full justify-between vip-cartao-salvo-content-wrapper"
                >
                  <div class="flex flex-col">
                    <span class="text-sm mb-1">{{ cartao.bandeira }}</span>
                    <span
                      class="text-sm tracking-tight vip-color-secondary-default fs-mask"
                    >
                      {{ cartao.numero_cartao | pagamentoTextoPipe }}
                    </span>
                  </div>
                  <vip-button-icon-rounded
                    size="medium"
                    type="normal"
                    (click)="
                      deletarCartao.emit(cartao);
                      $event.stopPropagation();
                      removerDadosCartaoSalvo()
                    "
                  >
                    <i class="vip-color-error-main icon-delete_outline"></i>
                  </vip-button-icon-rounded>
                </div>
              </div>
            </vip-selectable-card>
          </ng-container>
          <vip-button
            *ngIf="cartoes.length > 3"
            class="vip-btn-ver-mais-cartoes"
            data-cy="vip-btn-ver-mais-menos-cartoes"
            type="normal"
            size="small"
            (btnClick)="handleVerMais()"
          >
            {{ 'ver ' + (cartoesExibidos.length > 3 ? 'menos' : 'mais') }}
          </vip-button>
        </ng-container>
        <ng-template #formasPagamentoDefaultTemplate>
          <div>
            <ng-container>
              <vip-card-tipo-pagamento
                (tipoPagamento)="
                  limparFormaPagamentoSelecionada.emit(); tipoPagamento = $event
                "
                [tipoChecked]="tipoPagamento"
                [showOpcoes]="showOpcoesPagamento"
                data-cy="vip-tipo-pagamento"
              >
              </vip-card-tipo-pagamento>
            </ng-container>
          </div>
        </ng-template>
        <div *ngIf="carteiraDigital.length > 0">
          <span
            class="font-bold text-md vip-color-first-default mt-2 mb-3 block"
          >
            Carteira Digital
          </span>
          <ng-container *ngFor="let formaPagamento of carteiraDigital">
            <vip-card-forma-pagamento
              data-cy="forma-pagamento-carteira-digital"
              class="block mb-2"
              [formaPagamento]="formaPagamento"
              [formaPagamentoSelecionada]="formaPagamentoSelecionada"
              (formaPagamentoChange)="
                formaPagamentoChange.emit($event);
                cartaoSalvoChange.emit(null);
                tipoPagamento = ''
              "
            >
            </vip-card-forma-pagamento>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'entrega'">
        <div
          class="text-tiny vip-color-first-default text-center mb-3"
          *ngIf="desativaOnline"
        >
          <div>
            O pagamento online foi bloqueado devido à várias tentativas falhas.
          </div>
          <div>Selecione um método para realizar o pagamento na entrega</div>
        </div>
        <ng-container *ngFor="let formaPagamento of pagamentoPos">
          <vip-card-forma-pagamento
            data-cy="forma-pagamento-entrega"
            class="block mb-2"
            [formaPagamento]="formaPagamento"
            [formaPagamentoSelecionada]="formaPagamentoSelecionada"
            (formaPagamentoChange)="
              formaPagamentoChange.emit($event);
              cartaoSalvoChange.emit(null);
              tipoPagamento = ''
            "
          >
          </vip-card-forma-pagamento>
        </ng-container>
      </ng-container>
    </ng-container>
  </section>
  <div class="sticky bottom-0 p-3 vip-background-app-background">
    <vip-button
      (btnClick)="onConfirm()"
      [disabled]="
        !selectedOptions && (!permitirSalvarCartao || cartoes.length === 0)
      "
      data-cy="forma-pagamento-confirmar"
    >
      {{
        formaPagamentoSelecionada
          | textConfirmButtonFormaPagamento
            : permitirSalvarCartao
            : cartoes.length > 0
            : !!cartaoSelecionadoId
      }}
    </vip-button>
  </div>
</div>
