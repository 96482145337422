import { Pipe, PipeTransform } from '@angular/core';
import { TituloPagamentoEnum } from '@vip/core';

@Pipe({
    name: 'iconPagamentoOnlinePipe',
    pure: true,
    standalone: false
})
export class IconPagamentoOnlinePipe implements PipeTransform {
  transform(titulo: string): string {
    let result = '';
    switch (titulo) {
      case TituloPagamentoEnum.CREDITO || TituloPagamentoEnum.DEBITO:
        result = 'icon-credit_card';
        break;
      case TituloPagamentoEnum.CARTEIRA_DIGITAL:
        result = 'icon-pix';
        break;
      case TituloPagamentoEnum.VOUCHER:
        result = 'icon-fastfood';
        break;
      case TituloPagamentoEnum.CARTAO_DA_LOJA:
        result = 'icon-card_membership';
        break;
    }
    return result;
  }
}
