import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SolicitarProdutoRequest } from '@vip/api';
import {
  IApplicationMenuItem,
  ICarrinho,
  ICliente,
  IProduto,
  IProdutosCarrinhoGroup,
} from '@vip/core';
import { SearchEvent } from '@vip/ui/search';
import { EntregaRetiradaPropertiesBaseDirective } from '@vip/views/entrega-retirada';
import { Observable } from 'rxjs';

@Directive()
export abstract class HeaderDesktopBaseDirective extends EntregaRetiradaPropertiesBaseDirective {
  private _cliente: ICliente | null = null;
  protected _searchValue = '';

  isLogged = false;

  @Input()
  logo!: string;

  @Input()
  isTelevendas = false;

  @Input()
  qtdItensCarrinho?: number;

  @Input()
  minhaContaOptions: IApplicationMenuItem[] = [];

  @Input()
  disableEntregaRetirada = false;

  @Input()
  set cliente(value: ICliente | null) {
    this.isLogged = Boolean(value);
    this._cliente = value;
  }
  get cliente() {
    return this._cliente;
  }

  @Input()
  isFocused = false;

  @Input()
  termos: string[] | null = null;

  @Input()
  produtos: IProduto[] | null = null;

  @Input() produtosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;

  @Input() produtosCarrinho: IProdutosCarrinhoGroup[] = [];
  @Input() produtosFavoritos: IProduto[] = [];
  @Input() produtosMaisVendidos: IProduto[] = [];
  @Input() carrinhoVazio = true;
  @Input() carrinho?: ICarrinho | null = null;
  @Input() combosNoCarrinho?: Map<
    number,
    { item_id: number; quantidade: number; observacao?: string }
  > | null = null;
  @Input()
  closeDropdownEntregaRetirada$!: Observable<void>;

  @Input()
  set searchValue(value: string) {
    this._searchValue = value;
  }
  get searchValue() {
    return this._searchValue;
  }

  @Input() loading = false;

  @Input() isDesktop = true;

  @Input()
  scrollingShadow = false;

  @Input()
  loadingBuscaRapida = false;

  @Input() clientesTelevenda: ICliente[] = [];

  @Input() loadingTelevendas = false;

  @Output() showMoreClick = new EventEmitter();
  @Output() addListClick = new EventEmitter();
  @Output() produtoChanged = new EventEmitter();
  @Output() resumeClick = new EventEmitter();
  @Output() deleteItemClick = new EventEmitter();
  @Output() deleteCarrinhoClick = new EventEmitter();
  @Output() aviseMeClick = new EventEmitter<number>();
  @Input() aviseMeEnviados: Map<number, boolean> | null = new Map<
    number,
    boolean
  >();

  @Output()
  goToLoginPage = new EventEmitter<void>();

  @Output()
  goToSelectClienteTelevendasPage = new EventEmitter<void>();

  @Output()
  cadastrarEndereco = new EventEmitter<Event>();

  @Output()
  alterarEndereco = new EventEmitter<Event>();

  @Output()
  searchClick = new EventEmitter<SearchEvent>();

  @Output()
  goLogout = new EventEmitter<void>();

  @Output()
  search: EventEmitter<SearchEvent> = new EventEmitter<SearchEvent>();

  @Output()
  clickSearchByTerm: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  clickProduct: EventEmitter<IProduto> = new EventEmitter<IProduto>();

  @Output()
  clickSpinChange = new EventEmitter();

  @Output()
  enviarFormSolicitarProduto = new EventEmitter<SolicitarProdutoRequest>();

  @Output()
  tagClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clickMenu: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  informarCepClick = new EventEmitter<void>();

  @Output()
  encerrarSessaoTelevandas = new EventEmitter<void>();

  @Output()
  searchClickTelevendas = new EventEmitter<SearchEvent>();

  @Output()
  itemSelected = new EventEmitter();

  @Output()
  selectedFilial = new EventEmitter();

  @Output() openModalCepRef = new EventEmitter();

  @Output() openDesktopCepModal = new EventEmitter();

  handleSearchEvent(event: SearchEvent) {
    event.stopPropagation();
    this.searchValue = event.value;
    this.searchClick.emit(event);
  }

  handleSearchClick(event: Event) {
    event.stopPropagation();
    const newEvent = new SearchEvent('click', '', event);
    this.searchClick.emit(newEvent);
  }

  handleQuickSearchEvent(event: SearchEvent) {
    event.stopPropagation();
    this.searchValue = event.value;
    this.search.emit(event);
  }

  handleMenuClick() {
    this.clickMenu.emit();
  }

  handleDeleteCarrinhoClick() {
    this.deleteCarrinhoClick.emit();
  }

  get isValido() {
    if (this.isDesktop)
      return this.carrinho
        ? this.carrinho.preco > this.carrinho.valor_minimo
        : false;
    return true;
  }

  handleResumeClick() {
    this.resumeClick.emit(this.isValido);
  }
}
