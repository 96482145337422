import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ICliente,
  IMAGES_PATH,
  IProduto,
  ITelevendas,
  LayoutUtilsService,
} from '@vip/core';
import { DropdownDirective } from '@vip/ui/dropdown';
import { HeaderDesktopBaseDirective } from '../header-desktop/header-desktop-base.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISpinEventComProduto } from '@vip/ui/spin';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SearchEvent } from '@vip/ui/search';
import { filter } from 'rxjs/operators';
import { CampanhaPatrocinadoService } from '@vip/api';
import { ValidarCepComponent } from '@vip/views/validar-cep';
import { ModalService } from '@vip/ui/modal';

@UntilDestroy()
@Component({
    selector: 'vip-header-desktop-sub-menu',
    templateUrl: './header-desktop-sub-menu.component.html',
    styleUrls: ['./header-desktop-sub-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderDesktopSubMenuComponent
  extends HeaderDesktopBaseDirective
  implements AfterViewInit
{
  @Input() borderInifite = false;
  @Input() fallbackImageUrl?: string;
  @Input()
  exibeCashback!: boolean;

  @Input()
  saldoCashback = 0;

  @Input()
  saldoCashbackError: string | null = null;

  @Input()
  quantidadeNaoLida = 0;

  @Input()
  set filiaisTelevendas(value: ITelevendas | null) {
    this._filiaisTelevenda = value ?? null;
  }

  get filiaisTelevendas(): ITelevendas | null {
    return this._filiaisTelevenda;
  }

  @Input() clienteTelevendasSearch: ICliente[] | null = null;

  @ViewChildren(DropdownDirective)
  dropdowns!: QueryList<DropdownDirective>;
  dropdownEntregaRetiradaOpen = false;

  dropdownMinhaContaOpen = false;

  dropdownCartOpen = false;

  lockSearchDropdown = false;

  dropdownTelevendaContaOpen = false;

  form!: UntypedFormGroup;
  _filiaisTelevenda: ITelevendas | null = null;

  validations = {
    produto: {
      required: 'Por favor, informe um produto.',
    },
  };

  abrirModalProcurarProduto = false;

  isFocusOnSearchInput = false;

  constructor(
    @Inject(IMAGES_PATH) private imagesPath: string,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private campanhaPatrocinadoService: CampanhaPatrocinadoService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService<ValidarCepComponent>
  ) {
    super();

    this.isDesktop = this.layoutUtilsService.isDesktop();

    this.form = this.fb.group({
      produto: ['', [Validators.required]],
      codigo_barras: [''],
      observacao: [''],
    });
  }

  get televendasLogo(): string {
    return `${this.imagesPath}logo-televendas.png`;
  }

  get iconEntregaRetirada(): string {
    if (!this.enderecoSelecionado && !this.retiradaLoja) {
      return 'icon-location_on';
    }
    return this.retiradaLoja ? 'icon-store_mall' : 'icon-location_on';
  }

  get logoSrc(): string {
    return this.isTelevendas ? this.televendasLogo : this.logo;
  }

  get isTelevendasSemCliente(): boolean {
    return this.isTelevendas && !this.cliente;
  }

  @HostListener('document:mousedown', ['$event'])
  checkToCloseDropdownCart(event: Event) {
    const { target } = event;
    if (
      target &&
      (target as HTMLElement).getAttribute('aria-controls') ===
        'dropdown-carrinho' &&
      this.dropdowns.last.open
    ) {
      this.dropdowns.last.close();
    }
  }

  ngAfterViewInit(): void {
    this.openSearchDropdown();
    this.verifyIsSearchPage(this.getRouteSnapshotUrl());
    this.initListenerCloseDropdownEntregaRetirada();
  }

  openSearchDropdown(): void {
    this.search
      .pipe(
        untilDestroyed(this),
        filter(({ originalEvent }) => {
          if (originalEvent) {
            return originalEvent.type !== 'UrlEvent';
          }
          return true;
        })
      )
      .subscribe(({ value, type }) => {
        const { length } = value;
        const searchMenuDropdown = this.dropdowns.first;
        if (searchMenuDropdown && type === 'SearchEvent.enter') {
          searchMenuDropdown.close();
        } else if (searchMenuDropdown && !length) {
          searchMenuDropdown.close();
        } else if (
          searchMenuDropdown &&
          length >= 3 &&
          !searchMenuDropdown.open &&
          this.isFocusOnSearchInput
        ) {
          searchMenuDropdown.toggle();
        }
      });
  }

  registerSponsoredProductVisibility(produto: IProduto) {
    this.campanhaPatrocinadoService.visualizacao(produto);
  }

  handleUserBtnClick(): void {
    if (!this.isLogged && !this.isTelevendas) {
      this.goToLoginPage.emit();
    } else if (this.isTelevendas) {
      this.goToSelectClienteTelevendasPage.emit();
    }
  }

  encerrarSessaoTelevendas() {
    this.encerrarSessaoTelevandas.emit();
  }

  handleSearchByTermClick(termo: string): void {
    this.clickSearchByTerm.emit(termo);
    this.dropdowns.first.close();
  }

  handleProductClick(product: IProduto): void {
    this.clickProduct.emit(product);
    this.dropdowns.first.toggle();
  }

  handleSpinChangeClick(event: ISpinEventComProduto): void {
    this.clickSpinChange.emit(event);
  }

  onTagClick(): void {
    this.dropdowns.first.toggle();
  }

  openSearchMenuOnFocus(focused: boolean): void {
    this.isFocusOnSearchInput = focused;
    if (!focused) return;
    this.closeAllOpenedDropdowns();
    if (this.searchValue?.length >= 3) {
      this.dropdowns.first.toggle();
    }
  }

  closeAllOpenedDropdowns(): void {
    this.dropdowns.forEach((dropdown) => {
      if (dropdown) {
        dropdown.close();
      }
    });
  }

  handleCartItemClick(): void {
    this.dropdowns.first.toggle();
  }

  closeClick(): void {
    this.dropdowns.last.toggle();
  }

  initListenerCloseDropdownEntregaRetirada(): void {
    this.closeDropdownEntregaRetirada$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.dropdownEntregaRetiradaOpen) this.dropdowns.get(1)?.close();
      });
  }

  private getRouteRouterState(): any {
    return this.router.routerState;
  }

  private getRouteSnapshotUrl(): string {
    return this.getRouteRouterState().snapshot.url;
  }

  private getRouteSnapshotRootQueryParams(): { termo: string } {
    return this.getRouteRouterState().root.queryParams as { termo: string };
  }

  private verifyIsSearchPage(snapshotUrl: string): void {
    if (snapshotUrl.includes('busca')) {
      const { termo } = this.getRouteSnapshotRootQueryParams();
      this.searchValue = termo;
      const event = new SearchEvent('input', termo, new Event('UrlEvent'));
      this.handleQuickSearchEvent(event);
    }
  }

  formProcuraProduto(): void {
    this.abrirModalProcurarProduto = true;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.enviarFormSolicitarProduto.emit(this.form.value);
    this.abrirModalProcurarProduto = false;
    this.form.reset();
  }

  onHandleResumoClick(valido: boolean): void {
    this.closeAllOpenedDropdowns();
    if (!valido && this.isDesktop) return;
    super.handleResumeClick();
  }

  selecionarCliente(event: SearchEvent) {
    if (event) {
      this.itemSelected.emit(event);
    }
  }
}
