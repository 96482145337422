import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IClassificacaoMercadologica } from '@vip/core';

@Component({
    selector: 'vip-dropdown-departamentos',
    templateUrl: './dropdown-departamentos.component.html',
    styleUrls: ['./dropdown-departamentos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DropdownDepartamentosComponent {
  @Input() items: IClassificacaoMercadologica[] = [];

  @Output()
  itemClick = new EventEmitter<IClassificacaoMercadologica>();

  departamentoAberto?: IClassificacaoMercadologica;
  hasCategories = false;

  handleItemClick(event: IClassificacaoMercadologica) {
    this.itemClick.emit(event);
  }

  openSubmenu(departamento: IClassificacaoMercadologica) {
    this.departamentoAberto = undefined;

    if (departamento.children) {
      this.departamentoAberto = departamento;
      this.hasCategories = departamento.children.some(
        (item) => item.children && item.children.length > 0
      );
    }
  }

  closeSubmenu() {
    this.departamentoAberto = undefined;
  }
}
