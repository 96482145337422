import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
    selector: 'vip-container-modal-error',
    templateUrl: './container-modal-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContainerModalErrorComponent {
  @Output() supportCheck = new EventEmitter();

  confirmCheck() {
    window.location.reload();
  }

  onSupportCheck() {
    this.supportCheck.emit();
  }
}
