import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
    selector: 'vip-modal-captcha',
    templateUrl: './modal-captcha.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ModalCaptchaComponent {
  @Output()
  captchaChange = new EventEmitter<string>();

  captchaValidado = false;

  resolved(token: string | null): void {
    if (token === null) return;
    this.captchaValidado = true;
    this.captchaChange.emit(token);
  }
}
