import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IMAGES_PATH } from '@vip/core';
import { DialogService } from '@vip/ui/modal';
import { PagamentoCuponsBaseDirective } from './pagamento-cupons-base.directive';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
    selector: 'vip-pagamento-cupons',
    templateUrl: './pagamento-cupons.component.html',
    styleUrls: ['./pagamento-cupons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagamentoCuponsComponent
  extends PagamentoCuponsBaseDirective
  implements OnInit
{
  public imageUrl!: string;

  constructor(
    @Inject(IMAGES_PATH) private imagesPath: string,
    dialogService: DialogService
  ) {
    super(dialogService);
    this.imageUrl = `${this.imagesPath}price-tag.svg`;
  }

  ngOnInit(): void {
    this.ativarCodigo
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.inputCodigoPromocional.nativeElement.value = '';
      });
  }
}
