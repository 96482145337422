import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ICompra,
  IFormaPagamento,
  IPortador,
  ISelectOptions,
  LayoutUtilsService,
} from '@vip/core';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { FormaPagamentosFacade } from '@vip/state/forma-pagamento';
import { PagamentoService } from '../services/pagamentos.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { FilialFacade } from '@vip/state/filial';
import { filter, map, take } from 'rxjs/operators';
import { AnalyticsFacade } from '@vip/state/analytics';
import { mergeTakeOne } from '@vip/state/utils';
import { LayoutStore } from '@vip/state/layout';

@UntilDestroy()
@Component({
    selector: 'vip-pagamento-cartao-container',
    templateUrl: './pagamento-cartao-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagamentoCartaoContainerComponent implements AfterViewInit {
  compraEmProcesso$ = this.compraEmProcessoFacade.compraEmProcesso$;
  formaPagamentoSelecionada$ =
    this.formaPagamentosFacade.formaPagamentosSelecionadas$;
  formaPagamentosOptions: ISelectOptions[] = [];
  formasPagamentosPrePagas$!: Observable<IFormaPagamento[] | null>;
  tipoPagamento = '';
  permitirSalvarCartao$ = this.filialFacade.permitirSalvarCartao$;
  permitirBandeiraAutomatica$ = this.filialFacade.permitirBandeiraAutomatica$;
  permiteSelecaoManual$ = this.formaPagamentosFacade.permiteSelecaoManual$;
  bandeiraNaoAtendida$ = this.formaPagamentosFacade.bandeiraNaoAtendida$;
  tipoCartaoBandeiraAutomatica!: 'credito' | 'voucher';
  privateLabelBandeiraAutomatica = false;
  isDesktopResponsive = this.layoutUtilsService.isDesktopResponsive();

  constructor(
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private filialFacade: FilialFacade,
    private router: Router,
    public formaPagamentosFacade: FormaPagamentosFacade,
    public pagamentoService: PagamentoService,
    private activatedRoute: ActivatedRoute,
    private analyticsFacade: AnalyticsFacade,
    private layoutUtilsService: LayoutUtilsService,
    private layoutStore: LayoutStore
  ) {
    const pagamento = this.activatedRoute.snapshot.params;
    this.tipoPagamento = pagamento.tipo;
    this.tipoCartaoBandeiraAutomatica =
      pagamento.tipo === 'ref' ? 'voucher' : 'credito';

    switch (pagamento.tipo) {
      case 'privateLabel':
        this.formasPagamentosPrePagas$ =
          this.formaPagamentosFacade.pagamentos$.pipe(
            map((pagamento) => pagamento?.online.cartao_da_loja || null)
          );
        break;
      case 'Pre':
        this.formasPagamentosPrePagas$ =
          this.formaPagamentosFacade.pagamentos$.pipe(
            map((pagamento) => pagamento?.online.credito || null)
          );
        break;
      case 'ref':
        this.formasPagamentosPrePagas$ =
          this.formaPagamentosFacade.pagamentos$.pipe(
            map((pagamento) => pagamento?.online.voucher || null)
          );
        break;
    }
    this.formasPagamentosPrePagas$
      .pipe(untilDestroyed(this))
      .subscribe((formaPagamento) => {
        if (formaPagamento && formaPagamento.length > 0) {
          formaPagamento.forEach((pagamento) =>
            this.addFormaPagamentoOptions(pagamento)
          );

          if (
            formaPagamento.length < 2 &&
            this.tipoPagamento == 'privateLabel'
          ) {
            this.privateLabelBandeiraAutomatica = true;
          }
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.isDesktopResponsive) this.layoutStore.setHeaderVisibility(false);
  }

  private selectParcela(parcelaSelecionada: ISelectOptions) {
    if (parcelaSelecionada.info)
      this.pagamentoService.setValorAcrescimos(parcelaSelecionada.info);
  }

  private addFormaPagamentoOptions(formaPag: IFormaPagamento) {
    this.formaPagamentosOptions.push({
      value: formaPag.id.toString(),
      text: formaPag.descricao,
    });
  }

  confirmar(portador: IPortador) {
    const [parcela] = this.pagamentoService.parcelas;
    if (!parcela) {
      combineLatest([
        this.formaPagamentoSelecionada$,
        this.pagamentoService.compraEmProcessoValorFinalSemAcrescimo$,
      ])
        .pipe(take(1), untilDestroyed(this))
        .subscribe(([formaPgto, compraEmProcessoValorFinalSemAcrescimo]) => {
          if (formaPgto) {
            this.pagamentoService.updateParcelasOptions(
              formaPgto,
              compraEmProcessoValorFinalSemAcrescimo
            );
          }
          const [parcela] = this.pagamentoService.parcelas;
          this.selectParcela(parcela);
          this.compraEmProcessoFacade.setPortador({
            ...portador,
            installments: Number(parcela.value),
          });
        });
    } else {
      this.selectParcela(parcela);
      this.compraEmProcessoFacade.setPortador({
        ...portador,
        installments: Number(parcela.value),
      });
    }
    this.router.navigateByUrl('/pagamento');
    this.compraEmProcesso$
      .pipe(
        filter((compra): compra is ICompra => !!compra),
        take(1),
        untilDestroyed(this),
        mergeTakeOne(this.formaPagamentoSelecionada$)
      )
      .subscribe(([compra, formaPagamentoSelecionada]) => {
        this.analyticsFacade.addPagamentoInfo(
          formaPagamentoSelecionada.id,
          compra
        );
      });
  }
}
