import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  HostListener,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CustomValidators,
  TituloPagamentoEnum,
  ICompra,
  IFormaPagamento,
  LayoutUtilsService,
  MaskPatterns,
} from '@vip/core';
import { DateValidator } from './form-pagameto-cartao.validation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PagamentoCartaoDirective } from './pagamento-cartao-base.directive';
import { DialogService } from '@vip/ui/modal';
import { NgxMaskPipe } from 'ngx-mask';

@UntilDestroy()
@Component({
    selector: 'vip-pagamento-cartao',
    templateUrl: './pagamento-cartao.component.html',
    styleUrls: ['./pagamento-cartao.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagamentoCartaoComponent
  extends PagamentoCartaoDirective
  implements AfterViewInit, OnInit, OnChanges
{
  formGroup: UntypedFormGroup;
  readonly TITULO_PAGAMENTO_ENUM = TituloPagamentoEnum;
  cc_brand?: string;
  private timeoutID!: number;

  readonly masks = {
    cpf: MaskPatterns.CPF,
    data: MaskPatterns.DATA,
  };

  validations = {
    default: {
      required: 'Campo obrigatório',
    },
    cpf_portador: {
      mask: 'CPF inválido',
      invalidCpf: 'CPF inválido',
      required: 'Campo obrigatório',
    },
    cc_number: {
      mask: 'Número de cartão inválido',
      minlength: 'Número de cartão inválido',
      required: 'Campo obrigatório',
      maxlength: 'Informe no máximo 16 digitos',
    },
    cc_expire_date: {
      invalid: 'Data inválida',
      mask: 'Data inválida',
      required: 'Campo obrigatório',
    },
    cc_cvv: {
      mask: 'CVV inválido',
      required: 'Campo obrigatório',
    },
  };
  latestAddedValidators?: ValidatorFn[];
  latestUserPasteInput = '';
  @Input()
  isDesktopResponsive = false;

  @Input()
  override set compraEmProcessoError(compraEmProcessoError: boolean) {
    if (compraEmProcessoError) {
      this.limparForm(true);
    }
  }
  @Output() confirmarClick = new EventEmitter();
  @Output() alterarBandeiraCartao = new EventEmitter();
  @Output() buscarBandeiraCartao = new EventEmitter<{
    cardNumber: string;
    makeRequestAgain?: boolean;
  }>();
  @Output() backClick = new EventEmitter<void>();

  mask = '';
  makeRequestCardNumberAgain = false;
  readonly numeroDigitos = 14;

  isDesktop = this.layoutUtilsService.isDesktopWithoutScreenWidth();

  private _ccNumber = '';

  public set ccNumber(value: string) {
    this._ccNumber = value;
    this.formGroup.controls['cc_number'].setValue(
      this.NgxMaskPipe.transform(this.ccNumber, this.mask)
    );
  }

  public get ccNumber(): string {
    return this._ccNumber;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    public layoutUtilsService: LayoutUtilsService,
    dialogService: DialogService,
    private NgxMaskPipe: NgxMaskPipe
  ) {
    super(dialogService);
    this.formGroup = this.formBuilder.group(
      {
        cc_cvv: [
          '',
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
        cc_expire_date: [''],
        cc_expire_month: [''],
        cc_expire_year: [''],
        cpf_portador: ['', [CustomValidators.cpf, Validators.required]],
        cc_holder: ['', [Validators.required]],
        cc_number: ['', [Validators.required]],
        cc_brand: ['', [Validators.required]],
        criar_token: [false],
        installments: [''],
      },
      {
        validator: [DateValidator('cc_expire_date')],
      }
    );
  }

  _formaPagamentoSelecionada: IFormaPagamento | null = null;
  @Input()
  set formaPagamentoSelecionada(value: IFormaPagamento | null | undefined) {
    if (this._formaPagamentoSelecionada?.id === value?.id) return;

    this._formaPagamentoSelecionada = value || null;
    this.formGroup.controls.cc_brand.setValue(value?.id.toString());
    this.getMascara();
    this.limparForm();
  }
  get formaPagamentoSelecionada(): IFormaPagamento | null {
    return this._formaPagamentoSelecionada;
  }
  _compraEmProcesso: ICompra | null = null;
  @Input()
  set compraEmProcesso(value: ICompra | null | undefined) {
    if (this._compraEmProcesso?.id === value?.id) return;

    this._compraEmProcesso = value || null;
    if (value?.portador) {
      this.formGroup.patchValue(value.portador);
      this.cc_brand = value.portador.cc_brand;
    }
  }
  get compraEmProcesso() {
    return this._compraEmProcesso;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'installments' in changes &&
      this.isDesktop &&
      changes['installments'].currentValue.length > 0
    ) {
      this.formGroup.addControl(
        'installments',
        this.formBuilder.control('', [Validators.required])
      );
    }

    if (
      this.formaPagamentoSelecionada?.bandeira !== 'ticket_alimentacao' &&
      this.formaPagamentoSelecionada?.bandeira !== 'ticket_restaurante'
    ) {
      this.formGroup
        .get('cc_expire_date')
        ?.setValidators([Validators.required]);
    } else {
      this.formGroup.get('cc_expire_date')?.clearValidators();
    }
    this.formGroup.get('cc_expire_date')?.updateValueAndValidity();
  }

  ngOnInit(): void {
    if (!this.isDesktop) {
      this.initListenerBandeiraNaoAtendida();
    }
    this.resetarCcNumber$?.pipe(untilDestroyed(this)).subscribe(() => {
      this.formGroup.controls['cc_number'].reset();
      this.ccNumber = '';
      this.alterarBandeiraCartao.emit('');
    });
  }

  ngAfterViewInit(): void {
    if (this.cc_brand) {
      this.alterarBandeiraCartao.emit(this.cc_brand);
    }
    if (
      this.permitirSalvarCartao &&
      this.formaPagamentoSelecionada?.adquirente?.permitir_cofre_cartoes
    )
      this.formGroup.get('criar_token')?.setValue(true);

    if (this.permitirBandeiraAutomatica && !this.permiteSelecaoManual) {
      const { value } = this.formGroup.controls['cc_number'];
      if (value.length >= this.numeroDigitos) {
        this.buscarBandeiraCartao.emit({
          cardNumber: value,
          makeRequestAgain: true,
        });
      }
    }

    if (!this.formGroup.controls['cc_number'].value)
      this.mask = '0000 0000 0000 0000';

    if (
      this.mask &&
      !this.formGroup.controls['cc_number'].value &&
      this.permitirBandeiraAutomatica &&
      !this.permiteSelecaoManual
    ) {
      this.latestAddedValidators = [Validators.minLength(this.mask.length)];
      this.formGroup.controls['cc_number'].addValidators(
        this.latestAddedValidators
      );
      this.formGroup.updateValueAndValidity();
    }

    this.makeRequestCardNumberAgain = true;
    this.limparForm$
      ?.pipe(untilDestroyed(this))
      .subscribe(() => this.limparForm(true));
    this.formEmitter.emit(this.formGroup);
  }

  submit() {
    if (this.formGroup.valid) {
      if (
        this.formaPagamentoSelecionada?.bandeira !== 'ticket_alimentacao' &&
        this.formaPagamentoSelecionada?.bandeira !== 'ticket_restaurante'
      ) {
        const date = this.formGroup.controls.cc_expire_date.value.split('/');
        this.formGroup.controls.cc_expire_month.setValue(date[0]);
        this.formGroup.controls.cc_expire_year.setValue(date[1].slice(-2));
        if (date[1].length === 2) {
          const firstCurrentYearNumbers = String(
            new Date().getFullYear()
          ).slice(0, 2);
          this.formGroup.controls.cc_expire_date.setValue(
            `${date[0]}/${firstCurrentYearNumbers}${date[1]}`
          );
        }
      }
      const { value } = this.formGroup;
      this.confirmarClick.emit({ ...value });
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  getMascara(): void {
    if (!this.formaPagamentoSelecionada?.mascara_cartao) return;

    this.mask = this.formaPagamentoSelecionada?.mascara_cartao?.replace(
      /9/g,
      '0'
    );
    const cardNumber =
      this.latestUserPasteInput.length > this.ccNumber.length
        ? this.latestUserPasteInput
        : this.ccNumber;

    if (!cardNumber) return;

    if (this.latestAddedValidators)
      this.formGroup.controls['cc_number'].removeValidators(
        this.latestAddedValidators
      );

    this.latestAddedValidators = [Validators.minLength(this.mask.length)];
    this.formGroup.controls['cc_number'].addValidators(
      this.latestAddedValidators
    );

    this.formGroup.controls['cc_number'].setValue(
      this.NgxMaskPipe.transform(cardNumber, this.mask)
    );
    this.formGroup.updateValueAndValidity();
    this.formGroup.controls['cc_number'].markAsTouched();
    this.ccNumber = this.formGroup.controls['cc_number'].value;
  }

  @HostListener('paste', ['$event'])
  maskClear(event: ClipboardEvent) {
    this.latestUserPasteInput = '';
    this.latestUserPasteInput =
      event.clipboardData?.getData('text/plain') || '';
    if (this.isDesktop) return;
    this.mask = '';
    this.formGroup.controls['cc_number'].setValue(this.latestUserPasteInput);
  }

  @HostListener('keyup', ['$event'])
  prevent(event: Event) {
    event.preventDefault();
  }

  onBlurCcNumber() {
    this.formGroup.controls.cc_number.markAsTouched();
    if (!this.isDesktop && this.ccNumber)
      this.formGroup.controls['cc_number'].setValue(this.ccNumber);
  }

  handleBuscarBandeira(event: Event): void {
    event.preventDefault();
    this.verificaFormValido();
    const { value } = this.formGroup.controls['cc_number'];

    if (this.privateLabelBandeiraAutomatica && value) {
      this.alterarBandeiraCartao.emit(this.formaPagamentosOptions[0].value);
    } else if (this.privateLabelBandeiraAutomatica) {
      this.alterarBandeiraCartao.emit('');
    }

    if (
      !this.permitirBandeiraAutomatica ||
      value.replace(/\s/g, '').length < this.numeroDigitos
    )
      return;

    if (event instanceof InputEvent) {
      const { inputType } = event;
      this.makeRequestCardNumberAgain = inputType === 'insertFromPaste';

      if (inputType === 'deleteContentBackward') {
        this.latestUserPasteInput = '';
        this.alterarBandeiraCartao.emit('');
      }
    }

    this.buscarBandeiraCartao.emit({
      cardNumber: value.replace(/[^0-9]/g, ''),
      makeRequestAgain: this.makeRequestCardNumberAgain,
    });

    this.makeRequestCardNumberAgain = false;
  }

  private limparForm(isTabChanged?: boolean) {
    this.formGroup?.patchValue({
      cc_expire_date: '',
      cc_expire_month: '',
      cc_expire_year: '',
      cc_cvv: '',
      cpf_portador: '',
      cc_holder: '',
      criar_token: false,
      installments: '1',
    });
    this.formGroup.markAsUntouched();
    this.parcelaSelecionada = '1';
    if (isTabChanged) {
      this.formaPagamentosOptions = this.formaPagamentosOptions.map(
        (formaPagamento) => {
          formaPagamento.selected = false;
          return formaPagamento;
        }
      );
      this.formGroup.controls['cc_brand'].setValue('');
      this.formGroup.controls['cc_number'].setValue('');
      this.ccNumber = '';
      this.formGroup.controls['cc_brand'].markAsUntouched();
    }
    if (this.formGroup.controls['cc_number'].value) {
      this.formGroup.controls['cc_number'].markAsTouched();
    }
  }

  verificaFormValido() {
    if (!this.isDesktop || this.isDesktopResponsive) return;

    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
    if (this.formGroup.valid) {
      this.timeoutID = window.setTimeout(() => this.submit(), 500);
    } else {
      this.timeoutID = window.setTimeout(() => this.verificaFormValido(), 500);
    }
  }

  aoAlterarBandeiraCartao = (alterarBandeiraCartao: any) => {
    this.alterarBandeiraCartao.emit(alterarBandeiraCartao);
  };
}
