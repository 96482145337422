import { Action, createReducer, on } from '@ngrx/store';
import { GenericStoreStatusEnum, IGenericState } from '@vip/state/utils';

import * as SessaoActions from './sessao.actions';

export const SESSAO_FEATURE_KEY = 'sessao';

export type SessaoState = IGenericState<{ sessaoId: string } | null>;

export const initialState: SessaoState = {
  data: null,
  status: GenericStoreStatusEnum.PENDING,
  error: '',
};

const sessaoReducer = createReducer(
  initialState,
  on(SessaoActions.init, (state) => ({
    ...state,
    status: GenericStoreStatusEnum.LOADING,
    error: '',
  })),
  on(SessaoActions.loadSessaoSuccess, (state, { sessaoId }) => ({
    ...state,
    data: { sessaoId },
    status: GenericStoreStatusEnum.SUCCESS,
    error: '',
  })),
  on(SessaoActions.loadSessaoFailure, (state, { error }) => ({
    ...state,
    status: GenericStoreStatusEnum.ERROR,
    error: error.error.error,
  }))
);

export function reducer(state: SessaoState | undefined, action: Action) {
  return sessaoReducer(state, action);
}
