import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs/operators';

import { SessaoApiService } from '@vip/api';
import { ClienteActions } from '@vip/state/cliente';
import { CarrinhoActions } from '@vip/state/carrinho';
import { SessaoService } from '../services/sessao.service';
import * as SessaoActions from './sessao.actions';

@Injectable()
export class SessaoEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessaoActions.init, ClienteActions.logoutSuccess),
      fetch({
        run: () =>
          this.sessaoApiService.getSessaoId().pipe(
            map((res) => {
              this.sessaoService.setSessaoId(res.data.sessao_id);
              return SessaoActions.loadSessaoSuccess({
                sessaoId: res.data.sessao_id,
              });
            })
          ),
        onError: (action, error) => SessaoActions.loadSessaoFailure({ error }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private sessaoApiService: SessaoApiService,
    private sessaoService: SessaoService
  ) {}
}
