import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SelectEvent } from '@vip/ui/select';
import { PagamentoCartaoDirective } from '@vip/views/pagamento';

@Component({
    selector: 'vip-cartao-loja-opcao-pagamento',
    templateUrl: './cartao-loja-opcao-pagamento.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CartaoLojaOpcaoPagamentoComponent extends PagamentoCartaoDirective {
  selecionarParcela(parcelaSelecionada: SelectEvent): void {
    const parcelaSelecionadaOption = this.parcelas?.find(
      (parcela) => parcela.value == parcelaSelecionada.value
    );
    this.selectParcela.emit(parcelaSelecionadaOption);
  }
}
