import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AddEditEnderecoRequest } from '@vip/api';
import { MeusDadosBaseDirective } from '@vip/container/meus-dados';
import { LayoutUtilsService } from '@vip/core';
import { CepFacade } from '@vip/state/cep';
import { ClienteFacade } from '@vip/state/cliente';
import { EnderecoFacade } from '@vip/state/endereco';
import { FilialFacade } from '@vip/state/filial';
import { EnderecoComponent } from '@vip/views/endereco';
import {
  CompletarCadastroHelperService,
  MeusDadosComponent,
} from '@vip/views/meus-dados';
import { take } from 'rxjs/operators';

@Component({
    selector: 'vip-modal-completar-cadastro',
    templateUrl: './modal-completar-cadastro.component.html',
    styleUrls: ['./modal-completar-cadastro.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ModalCompletarCadastroComponent extends MeusDadosBaseDirective {
  @ViewChild(MeusDadosComponent)
  meusDadosComponent!: MeusDadosComponent;
  @ViewChild(EnderecoComponent)
  enderecoComponent?: EnderecoComponent;

  closeModal = new EventEmitter<void>();
  formEndereco?: AddEditEnderecoRequest;
  saveDadosPessoais = false;

  constructor(
    router: Router,
    clienteFacade: ClienteFacade,
    enderecoFacade: EnderecoFacade,
    filialFacade: FilialFacade,
    cepFacade: CepFacade,
    layoutUtilsService: LayoutUtilsService,
    private completarCadastroHelperService: CompletarCadastroHelperService
  ) {
    super(
      router,
      clienteFacade,
      filialFacade,
      enderecoFacade,
      cepFacade,
      layoutUtilsService
    );
  }

  onConfirm(): void {
    this.meusDadosComponent.submitForms();
    if (this.enderecoComponent)
      this.enderecoComponent.formEnderecoComponent.submit();

    if (
      !this.meusDadosComponent.isFormsValid() ||
      (this.enderecoComponent && !this.enderecoComponent.form.valid)
    )
      return;

    this.sendDadosPessoais();
    this.sendEndereco();
    this.closeModal.emit();
  }

  onCancel(): void {
    this.router.navigateByUrl('/');
    this.closeModal.emit();
  }

  private sendDadosPessoais(): void {
    const someFormChange =
      this.meusDadosComponent.formPessoaFisica?.formGroup.dirty ||
      this.meusDadosComponent.formPessoaJuridica?.formGroup.dirty;
    this.saveDadosPessoais = someFormChange;

    if (!someFormChange) {
      return;
    }

    this.meusDadosComponent.handleSave();

    if (!this.completarCadastroHelperService.subscribed) {
      this.completarCadastroHelperService.subscribed = true;
      this.completarCadastroHelperService.saveDados$
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-takeuntil
        .subscribe((saveData) => {
          this.saveMeusDados(saveData);
          if (this.formEndereco) this.saveEndereco(this.formEndereco, true);
          this.completarCadastroHelperService.subscribed = false;
        });
    }
  }

  private sendEndereco(): void {
    if (!this.enderecoComponent) return;

    this.enderecoComponent.possuiEnderecoPrincipalCadastrado = false;
    this.enderecoComponent.onSave(true);
    this.formEndereco = this.enderecoComponent.formToSend;
    if (!this.saveDadosPessoais && this.formEndereco)
      this.saveEndereco(this.formEndereco, true);
  }
}
