<div
  *ngIf="hasLink"
  class="fixed bottom-4 right-3 rounded-full flex items-center justify-center z-[1]"
  [class.vip-higher-up]="higherUp()"
>
  <vip-button-icon-rounded
    size="large"
    type="raised"
    data-cy="whatsapp"
    class="w-20 h-20 flex items-center justify-center"
    (btnClick)="openWhatsAppLink()"
  >
    <i class="icon-whatsapp text-[2.5rem] !text-[2.5rem]"></i>
  </vip-button-icon-rounded>
</div>
