import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { IMAGES_PATH } from '@vip/core';

interface RouterState {
  rota: 'app' | 'filial';
  error: number;
}

@Component({
    selector: 'vip-manutencao',
    templateUrl: './manutencao.component.html',
    styleUrls: ['./manutencao.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ManutencaoComponent {
  @Input() mensagem = '';

  @Input() error?: RouterState;

  maintenanceImageUrl;

  constructor(@Inject(IMAGES_PATH) imagesPath: string) {
    this.maintenanceImageUrl = `${imagesPath}maintenance.svg`;
  }

  getDefaultMessage() {
    let message = 'No momento nossa loja está em manutenção ';

    if (this.error?.rota && this.error.error >= 0) {
      message += this.error.rota === 'app' ? '(SE' : '(FI';
      if (this.error.error >= 500) {
        message += '5';
      } else if (this.error.error >= 400) {
        message += '4';
      } else if (this.error.error >= 200) {
        message += 'SF';
      } else if (this.error.error === 0) {
        message += '0';
      } else {
        message += 'NR';
      }
      message += ')';
    }
    return message;
  }
}
