import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponentSkeletonComponent } from '@vip/ui/skeleton';

@Component({
    selector: 'vip-credito-opcao-pagamento-skeleton',
    templateUrl: './credito-opcao-pagamento-skeleton.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreditoOpcaoPagamentoSkeletonComponent extends BaseComponentSkeletonComponent {}
