<vip-card-basico
  [icon]="
    action === 'Retirada' ? 'icon-directions_walk' : 'icon-local_shipping'
  "
  [title]="action"
  [button]="
    editable && !tipoEntrega
      ? isRetirada
        ? 'Escolher e Agendar Retirada'
        : 'Escolher e Agendar Entrega'
      : ''
  "
  buttonType="raised"
  (buttonClick)="alterarTipoEntregaClick.emit(tipoEntrega)"
  [disableRipple]="true"
>
  <div>
    <div *ngIf="endereco" class="flex justify-between items-center">
      <div class="flex-1 vip-color-first-default">
        <p class="text-xs mb-0.5" data-cy="endereco-titulo">
          {{
            (isRetirada || isLojaAutonoma ? 'Retirar em ' : 'Entregar em ') +
              titulo
          }}
        </p>
        <p class="text-xs font-medium mb-0.5" *ngIf="logradouro">
          {{ logradouro }}
        </p>
        <p class="text-tiny vip-color-secondary-default">
          <span data-cy="complemento" *ngIf="complemento">
            {{ complemento }},
          </span>
          <span *ngIf="bairro">{{ bairro }}</span>
        </p>
      </div>
      <vip-button
        class="vip-alterar-btn"
        type="normal"
        size="small"
        *ngIf="editable"
        (btnClick)="alterarEnderecoClick.emit(endereco)"
      >
        <span class="text-xs vip-color-secondary-main">Alterar</span>
      </vip-button>
    </div>
    <hr
      class="vip-divider my-2"
      *ngIf="!isLojaAutonoma"
      [ngClass]="{ 'mt-2 mb-0': editable && !tipoEntrega }"
    />
    <div
      class="flex justify-between items-center"
      data-cy="entrega-retirada-item"
      *ngIf="tipoEntrega && !isLojaAutonoma"
    >
      <div class="flex-1">
        <p class="text-xs vip-color-secondary-main font-medium mb-0.5">
          {{ tipoEntrega.descricao }}
        </p>
        <p class="text-tiny vip-color-third-default font-medium mb-0.5">
          {{ dataEntregaFormatada }}
        </p>
        <p
          class="text-tiny font-medium vip-color-secondary-default"
          [class.vip-color-success-main]="
            (tipoEntrega.frete | valorFrete: tipoEntrega.desconto || 0) ===
            'Grátis'
          "
          data-cy="frete"
        >
          {{ tipoEntrega.frete | valorFrete: tipoEntrega.desconto || 0 }}
        </p>
      </div>
      <vip-button
        class="vip-alterar-btn"
        type="normal"
        size="small"
        *ngIf="editable"
        (btnClick)="alterarTipoEntregaClick.emit(tipoEntrega)"
      >
        <span class="text-xs vip-color-secondary-main vip-alterar-btn-text">
          Alterar
        </span>
      </vip-button>
    </div>
  </div>
</vip-card-basico>
