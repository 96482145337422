<vip-prazo-entrega
  [formaEntregaFromState]="formaEntrega"
  [tiposEntrega]="(tiposEntregas$ | async) ?? []"
  [taxaEntrega]="(taxaEntrega$ | async) ?? undefined"
  [taxaServico]="taxaServico$ | async"
  [horarioSelecionado$]="horarioSelecionado$"
  [tipoEntregaSelecionado]="tipoEntregaSelected"
  [exibirTiposEntrega]="exibirTiposEntrega$ | async"
  [exibirTiposRetirada]="exibirTiposRetirada$ | async"
  [hasValidaPrazo]="hasValidaPrazo"
  (entregaRetirada)="changeSelectTipoEntrega($event)"
  (horarioRetirada)="changeHorarioTipoEntrega($event)"
  [loadingTipoEntrega]="loadingTipoEntrega"
  [hasTipoRetiradaPrazoEntrega]="hasTipoRetiradaPrazoEntrega"
  (confirm)="confirmarPrazoEntrega()"
  [tipoEntrega]="entregaRetirada"
  [tipoEntregaSelecionadoPrazo]="tipoEntregaSelected"
  [loadingTeste]="(loading$ | async) || false"
  [desativarConfirmar]="desativarConfirmar"
  [hasErroOpen]="hasErroOpen"
></vip-prazo-entrega>
