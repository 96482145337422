import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IApplicationMenuItem } from '@vip/core';

@Component({
    selector: 'vip-minha-conta-menu',
    templateUrl: './minha-conta-menu.component.html',
    styleUrls: ['./minha-conta-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MinhaContaMenuComponent {
  @Input()
  options: IApplicationMenuItem[] = [];

  @Input()
  isLogged = false;

  @Input()
  exibeCashback = false;

  @Input()
  saldoCashback = 0;

  @Input()
  saldoCashbackError: string | null = null;

  @Input()
  quantidadeNaoLida = 0;

  @Output()
  goLogout = new EventEmitter<void>();

  @Output()
  closeDropdown = new EventEmitter<void>();
}
