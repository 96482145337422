import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { IClassificacaoMercadologica } from '@vip/core';

@Component({
    selector: 'vip-dropdown-secoes',
    templateUrl: './dropdown-secoes.component.html',
    styleUrls: ['./dropdown-secoes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DropdownSecoesComponent implements AfterViewChecked {
  @Input()
  items!: IClassificacaoMercadologica[];

  @Output()
  itemClick = new EventEmitter<IClassificacaoMercadologica>();

  @Input() hasCategories = false;

  @ViewChildren('itemsDropdown')
  itemsDropdown!: QueryList<ElementRef>;

  get hasShowMoreButton() {
    return this.items && this.items.length > this.showQtd;
  }

  itemsQuantity = 0;

  showQtd = 20;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.itemsQuantity = this.itemsDropdown.length;
    this.changeDetector.detectChanges();
  }

  handleButtonClick(event: IClassificacaoMercadologica) {
    this.itemClick.emit(event);
  }

  handleShowMore() {
    this.showQtd = this.items.length;
  }

  trackByFn(index: number, item: IClassificacaoMercadologica): number {
    return item.classificacao_mercadologica_id;
  }
}
