import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { IFormaPagamento } from '@vip/core';

@Component({
    selector: 'vip-card-forma-pagamento',
    templateUrl: './card-forma-pagamento.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardFormaPagamentoComponent {
  @Input() formaPagamento?: IFormaPagamento;
  @Input() formaPagamentoSelecionada?: IFormaPagamento | null;

  @Output() formaPagamentoChange = new EventEmitter();

  handleChangeFormaPagamento(formaPagamentoSelecionadaId: number) {
    if (
      this.formaPagamentoSelecionada &&
      this.formaPagamentoSelecionada.id == formaPagamentoSelecionadaId
    ) {
      this.formaPagamentoChange.emit();
    } else {
      this.formaPagamentoChange.emit(formaPagamentoSelecionadaId);
    }
  }
}
