import { ModalService } from '@vip/ui/modal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ICodigoPromocional,
  ICompra,
  ICompraDesconto,
  ICompraPagamento,
  ICupomCredito,
  IFormaPagamento,
  IProdutoCompra,
  ISelectOptions,
  ITaxaServico,
  ITipoEntrega,
} from '@vip/core';
import { ModalResumoProdutosComponent } from '@vip/views/resumo-compra';
import { ModalNumeroParcelasComponent } from '../modal-numero-parcelas/modal-numero-parcelas.component';
import { ModalCvvComponent } from '../modal-cvv/modal-cvv.component';
import { Subject, fromEvent } from 'rxjs';
import { delay, filter, take } from 'rxjs/operators';
import { ModalTrocoService } from '@vip/ui/card-pagamento';

@UntilDestroy()
@Component({
    selector: 'vip-pagamento',
    templateUrl: './pagamento.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagamentoComponent implements OnInit {
  @Input() cuponsSelecionados: ICupomCredito[] = [];
  @Input() exibirOpcoesFaturamento = false;
  @Input() opcoesFaturamento: string | null = null;
  @Input() opcaoFaturamento: string | null = null;
  @Input() produtosComDesconto: IProdutoCompra[] = [];
  @Input() valorDescontos = 0;
  @Input() descontos: ICompraDesconto[] = [];
  @Input() saldoCashback?: number | null;
  @Input() formaPagamentoSelecionada?: IFormaPagamento | null;
  @Input() totalCompra?: number;
  @Input() compraEmProcesso?: ICompra | null;
  @Input() valorAcrescimos?: number;
  @Input() codigoPromocional: ICodigoPromocional | null = null;
  @Input() loading = false;
  @Input() parcelas: ISelectOptions[] = [];
  @Input() pagamentos: ICompraPagamento[] = [];
  @Input() cartaoSelecionadoId: string | null = null;
  @Input() tipoEntregaSelecionado?: ITipoEntrega | null;
  @Input() valorTroco = 0;
  @Input() valorMinimoCashback = 0;
  @Input() excedeuLimiteTentativas = false;
  @Input() taxaEntrega?: number;
  pressBack = false;

  private _taxaServico: ITaxaServico | null = null;
  @Input()
  get taxaServico(): ITaxaServico | null {
    return {
      valor_taxa: this.compraEmProcesso
        ? this.compraEmProcesso.taxa_de_servico
        : null,
      mensagem: this._taxaServico?.mensagem || '',
    };
  }
  set taxaServico(taxaServico) {
    this._taxaServico = taxaServico;
  }

  @Output() removerCupomSelecionado = new EventEmitter();
  @Output() changedOpcaoFaturamento = new EventEmitter();
  @Output() finalizarCompraClick = new EventEmitter();
  @Output() changedValorUtilizadoCashback = new EventEmitter<number>();
  @Output() alterarTrocoValue = new EventEmitter<number>();
  @Output() removerCodigoPromocionalClick = new EventEmitter<string>();

  @Output()
  voltarClick = new EventEmitter<{
    compraId?: number;
    filialId?: number;
    cdId?: number;
  }>();

  @Output()
  selecionarParcelaClick = new EventEmitter();

  @Output()
  cvvValueChange = new EventEmitter<string>();

  limitProdutos = 2;
  alterouParcelas = false;
  finishSelecaoParcelasSubject = new Subject<void>();

  constructor(
    private router: Router,
    private modalService: ModalService<
      | ModalResumoProdutosComponent
      | ModalNumeroParcelasComponent
      | ModalCvvComponent
    >,
    private modalTrocoService: ModalTrocoService
  ) {
    this.subscribeToTrocoChange();
  }

  private openModalCVV(): void {
    const modalRef = this.modalService.openModal(ModalCvvComponent, {
      bottom: true,
    });

    if (modalRef) {
      const componentModalCvv = modalRef.instance as ModalCvvComponent;
      componentModalCvv.cvvChange
        .pipe(untilDestroyed(this))
        .subscribe(async (cvv) => {
          this.cvvValueChange.emit(cvv);
          await this.modalService.clearModal();
          this.finalizarCompraClick.emit();
        });
    }
  }

  private initBackNavigationListener() {
    fromEvent(window, 'popstate')
      .pipe(untilDestroyed(this), delay(1))
      .subscribe(() => {
        this.pressBack = true;
      });
  }

  ngOnInit(): void {
    this.initBackNavigationListener();
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (event): event is NavigationStart => event instanceof NavigationStart
        )
      )
      .subscribe(() => {
        this.pressBack = false;
      });
  }

  handleAlterarPagamento() {
    this.router.navigateByUrl('pagamento/forma-pagamento');
  }

  escolherCupons() {
    this.router.navigateByUrl('pagamento/cupons');
  }

  handleAlterarCashback(valorCashback: number): void {
    this.changedValorUtilizadoCashback.emit(valorCashback);
  }

  verItens() {
    const modalRef = this.modalService.openModal(ModalResumoProdutosComponent, {
      bottom: false,
    });

    if (modalRef) {
      (modalRef.instance as ModalResumoProdutosComponent).produtos =
        this.produtosComDesconto;
    }
  }

  openModalParcelas(event?: string): void {
    const modalRef = this.modalService.openModal(ModalNumeroParcelasComponent, {
      bottom: true,
    });

    if (modalRef) {
      const componentModalNumeroParcelas =
        modalRef.instance as ModalNumeroParcelasComponent;
      componentModalNumeroParcelas.parcelas = this.parcelas;
      componentModalNumeroParcelas.parcelaSelecionada = event;

      componentModalNumeroParcelas.selecionarParcela
        .pipe(untilDestroyed(this))
        .subscribe((parcelaSelecionada) => {
          this.selecionarParcelaClick.emit(parcelaSelecionada);
          this.modalService.clearModal();
          this.alterouParcelas = true;
          this.finishSelecaoParcelasSubject.next();
        });
    }
  }

  finalizarCompra(): void {
    if (this.cartaoSelecionadoId && !this.alterouParcelas) {
      const finishSelecaoParcelas$ =
        this.finishSelecaoParcelasSubject.asObservable();

      finishSelecaoParcelas$
        .pipe(untilDestroyed(this), take(1), delay(300))
        .subscribe(() => {
          this.openModalCVV();
        });
      this.openModalParcelas();
    } else if (this.cartaoSelecionadoId) {
      this.openModalCVV();
    } else {
      this.finalizarCompraClick.emit();
    }
  }

  subscribeToTrocoChange() {
    this.modalTrocoService.valorTrocoEventEmitter
      .pipe(untilDestroyed(this))
      .subscribe((valorTroco) => {
        this.alterarTrocoValue.emit(valorTroco);
      });
  }

  getTaxaEntregaSemDescontoCupom(): number {
    const valorFrete = this.compraEmProcesso?.frete || 0;
    const valorDescontoCampanha = this.tipoEntregaSelecionado?.desconto || 0;
    return valorFrete - valorDescontoCampanha;
  }

  getTaxaEntrega(): number {
    const taxaSemDescontoCupom = this.getTaxaEntregaSemDescontoCupom();
    const valorDescontoCupom = this.codigoPromocional?.descontoFrete || 0;
    let taxaEntrega = taxaSemDescontoCupom - valorDescontoCupom;
    taxaEntrega = taxaEntrega < 0 ? 0 : taxaEntrega;

    return taxaEntrega;
  }
}
