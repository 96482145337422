import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { PagamentoCarteiraDigitalBaseDirective } from './pagamento-carteira-digital-base.directive';

@Component({
    selector: 'vip-pagamento-carteira-digital',
    templateUrl: './pagamento-carteira-digital.component.html',
    styleUrls: ['./pagamento-carteira-digital.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PagamentoCarteiraDigitalComponent extends PagamentoCarteiraDigitalBaseDirective {}
