import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICartao, ISelectOptions, IFormaPagamento } from '@vip/core';
import { SelectEvent } from '@vip/ui/select';
import { PagamentoCartaoDirective } from '@vip/views/pagamento';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'vip-credito-opcao-pagamento',
    templateUrl: './credito-opcao-pagamento.component.html',
    styleUrls: ['./credito-opcao-pagamento.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreditoOpcaoPagamentoComponent
  extends PagamentoCartaoDirective
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  private _cartoes?: ICartao[] = [];
  @Input()
  tituloPagamento!: string;
  @Input()
  set cartoes(value: ICartao[] | undefined) {
    this._cartoes = value;
    this.cadastrandoNovoCartao = !value?.length;
  }
  get cartoes(): ICartao[] | undefined {
    return this._cartoes;
  }
  @Input() cartaoSelecionadoId?: string | null = null;

  @Output() buscarBandeiraCartao = new EventEmitter<{
    cardNumber: string;
    makeRequestAgain?: boolean;
  }>();

  @Output() resetarFormaPgto = new EventEmitter<void>();
  @Output()
  formCartaoSalvoEmitter = new EventEmitter<UntypedFormGroup>();
  @Output()
  cadastrarNovoCartaoEmitter = new EventEmitter<void>();

  cadastrandoNovoCartao = true;
  formaPgto: IFormaPagamento[] = [];
  formaPgtoOptions: ISelectOptions[] = [];
  cartaoSelecionado: ICartao | null = null;
  parcelaSelecionadaOption?: ISelectOptions;
  formGroup = this.formBuilder.group({
    installments: [1, [Validators.required]],
    cc_cvv: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
    ],
  });
  validations = {
    installments: {
      required: 'Campo obrigatório',
    },
    cc_cvv: {
      mask: 'CVV inválido',
      required: 'Campo obrigatório',
    },
  };

  private destroyed$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('permitirSalvarCartao' in changes) {
      this.setCadastrandoCartaoOnInit();
    }
  }

  ngOnInit(): void {
    if (this.limparForm$) {
      this.limparForm$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.cartaoSelecionadoId = null;
      });
    }
  }

  ngAfterViewInit(): void {
    this.formCartaoSalvoEmitter.emit(this.formGroup);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selecionarParcela(parcelaSelecionada: SelectEvent): void {
    const parcelaSelecionadaOption = this.parcelas?.find(
      (parcela) => parcela.value == parcelaSelecionada.value
    );
    this.selectParcela.emit(parcelaSelecionadaOption);
  }

  cadastrarNovoCartao(): void {
    this.cadastrandoNovoCartao = true;
    this.cartaoSelecionado = null;
    this.cartaoSelecionadoId = null;
    this.formGroup.reset();
    this.formGroup.get('cc_brand')?.reset();
    this.formaPagamentoSelecionada = null;
    this.cartaoSalvoChange.emit(null);
    this.cadastrarNovoCartaoEmitter.emit();
  }

  alterarCartaoSelecionado($event: ICartao | null): void {
    if ($event === null) {
      this.cartaoSelecionado = null;
      this.cartaoSelecionadoId = null;
      this.formGroup.get('installments')?.reset(1);
      this.formGroup.get('cc_cvv')?.reset('');
      this.resetarFormaPgto.emit();
      return;
    }

    if ($event !== null) {
      this.cadastrandoNovoCartao = false;
      this.cartaoSelecionadoId = $event.id;
    }
    this.cartaoSelecionado = $event;
    this.formGroup.reset();
    this.formGroup.get('installments')?.reset(1);
    this.formGroup.get('cc_cvv')?.reset('');
    this.cartaoSalvoChange.emit(this.cartaoSelecionado?.id);
  }

  checkCvv(): void {
    if (this.formGroup.value.cc_cvv && this.formGroup.value.cc_cvv.length > 2) {
      this.cvvValueChange.emit(this.formGroup.value.cc_cvv || '');
    }
  }

  private setCadastrandoCartaoOnInit(): void {
    this.cadastrandoNovoCartao =
      !this.permitirSalvarCartao || !this._cartoes?.length;
  }
}
