import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ICodigoPromocional, ICupomCredito, TipoCupomEnum } from '@vip/core';

@Component({
    selector: 'vip-card-cupons-compra',
    templateUrl: './card-cupons-compra.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardCuponsCompraComponent {
  tipoCupom = TipoCupomEnum;

  @Input()
  cupons: ICupomCredito[] = [];

  @Input()
  editable = true;

  @Input()
  codigoPromocional: ICodigoPromocional | null = null;

  @Output()
  escolherClick = new EventEmitter<void>();

  @Output()
  removerClick = new EventEmitter<ICupomCredito>();

  @Output()
  removerCodigoPromocionalClick = new EventEmitter<string>();

  get isRippleDisabled(): boolean {
    return !(this.editable && this.cupons?.length === 0);
  }

  get codigoPromocionalValor() {
    if (!this.codigoPromocional) {
      return null;
    }

    const [codigoPromocional] =
      this.codigoPromocional.descontosCodigoPromocional;
    let total = 0;
    let valor = 0;
    this.codigoPromocional.descontosCodigoPromocional.forEach(
      (compraDesconto) => {
        if (compraDesconto.desconto === this.tipoCupom.PORCENTAGEM) {
          valor += compraDesconto.valor;
          total += compraDesconto.total as number;
        } else {
          valor += compraDesconto.valor;
        }
      }
    );

    return {
      desconto: codigoPromocional.desconto,
      total,
      valor,
    };
  }

  handleCardClick() {
    if (this.editable && this.cupons?.length === 0) this.escolherClick.emit();
  }
}
