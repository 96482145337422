<div class="px-6 pt-4">
  <h5 class="text-xl vip-color-primary-main font-bold">
    Você excedeu o tempo limite para finalizar a compra
  </h5>
  <p class="font-normal pt-2 text-[16px] vip-color-second-default">
    Por favor, selecione novamente o tipo de entrega que deseja receber a sua
    compra
  </p>
  <vip-opcoes-entrega-retirada
    *ngIf="!tipoEntrega"
    [enderecos]="enderecos"
    [cdsEntrega]="cdsEntrega"
    [enderecoSelecionado]="enderecoSelecionado"
    [cdSelecionado]="cdSelecionado"
    [tiposEntrega]="getTiposEntregaOrdenados(tiposEntrega)"
    [tipoEntregaSelecionado]="tipoEntregaSelecionadoPrazo"
    [exibirTiposEntrega]="true"
    [formaEntregaFromState]="formaEntregaFromState"
    [horarioSelecionado$]="horarioSelecionado$"
    (tipoEntregaChanged)="changeSelectTipoEntrega($event)"
    (horarioChange)="changeHorarioTipoEntrega($event)"
    [isEntrega]="true"
    [isEditar]="false"
    [hasValidaPrazo]="hasValidaPrazo"
    [loadingValidaPrazo]="loadingTipoEntrega"
    [hasTipoRetiradaPrazoEntrega]="hasTipoRetiradaPrazoEntrega"
    [hasErroOpen]="hasErroOpen"
    [horarioSelecionadoString]="horarioSelecionadoString"
  ></vip-opcoes-entrega-retirada>
  <vip-opcoes-entrega-retirada
    *ngIf="tipoEntrega"
    [cdsRetirada]="cdsRetirada"
    [tiposEntrega]="getTiposEntregaOrdenados(tiposEntrega)"
    [cdSelecionado]="cdSelecionado"
    [isEntrega]="false"
    [tipoEntregaSelecionado]="tipoEntregaSelecionadoPrazo"
    [formaEntregaFromState]="formaEntregaFromState"
    [horarioSelecionado$]="horarioSelecionado$"
    [exibirTiposRetirada]="true"
    (cdChange)="cdChange.emit($event)"
    (enderecoEntregaChange)="enderecoEntregaChange.emit($event)"
    (tipoEntregaChanged)="changeSelectTipoEntrega($event)"
    (horarioChange)="changeHorarioTipoEntrega($event)"
    [hasValidaPrazo]="hasValidaPrazo"
    [loadingValidaPrazo]="loadingTipoEntrega"
    [hasTipoRetiradaPrazoEntrega]="hasTipoRetiradaPrazoEntrega"
    [hasErroOpen]="hasErroOpen"
    [horarioSelecionadoString]="horarioSelecionadoString"
  ></vip-opcoes-entrega-retirada>

  <div *ngIf="hasError" class="pb-4">
    <p class="vip-color-error-main font-normal text-[16px]">
      {{
        tipoEntregaSelecionadoPrazo === null
          ? messageError[0].title
          : messageError[1].title
      }}
    </p>
  </div>
</div>
<div class="vip-background-complementary w-full flex items-center justify-end">
  <div class="pr-6 py-3 w-full flex justify-end">
    <div class="w-[160px]">
      <vip-button
        [disabled]="loadingTipoEntrega || desativarConfirmar"
        data-cy="btn-confirma-cancelar-compra"
        [size]="'medium'"
        (click)="checkConfirmButtonState()"
        >Confirmar
      </vip-button>
    </div>
  </div>
</div>
