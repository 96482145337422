import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IProdutoCompra } from '@vip/core';

@Component({
    selector: 'vip-card-resumo-produtos',
    templateUrl: './card-resumo-produtos.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardResumoProdutosComponent {
  @Input()
  produtos: IProdutoCompra[] = [];

  @Input()
  limit = 2;

  @Input()
  title: 'Produtos' | 'Produtos com Desconto' = 'Produtos';

  @Output()
  itemProdutoClick = new EventEmitter();

  @Output()
  verProdutosClick = new EventEmitter();

  @Output()
  adicionarProdutosClick = new EventEmitter();

  get showButton(): boolean {
    return this.produtos.length >= 1;
  }

  get resumoProdutos() {
    return this.produtos.slice(0, this.limit);
  }
}
