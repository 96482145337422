import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ICentroDistribuicao,
  IEndereco,
  IProdutoCompra,
  ITipoEntregaCard,
} from '@vip/core';

@Component({
  selector: 'vip-card-entrega-retirada',
  templateUrl: './card-entrega-retirada.component.html',
  styleUrls: ['./card-entrega-retirada.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CardEntregaRetiradaComponent implements OnInit, OnChanges {
  @Input() produtos: IProdutoCompra[] = [];

  @Input() endereco?: IEndereco | null;

  @Input()
  action!: 'Entrega' | 'Retirada' | 'Entrega e Retirada';

  @Input()
  isRetirada: boolean | undefined = true;

  @Input()
  tipoEntrega?: ITipoEntregaCard | null = null;

  @Input()
  centroDistribuicao?: Pick<ICentroDistribuicao, 'endereco'> | null;

  @Input()
  dataEntregaFormatada?: string;

  @Input()
  editable = true;

  @Input()
  isLojaAutonoma = false;

  @Output()
  alterarEnderecoClick = new EventEmitter<IEndereco>();

  @Output()
  alterarTipoEntregaClick = new EventEmitter();

  @Input()
  titulo?: string;

  @Input()
  complemento?: string;

  @Input()
  bairro?: string;

  @Input()
  logradouro?: string;

  ngOnInit() {
    this.entregaRetirada();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.entregaRetirada();
    this.popularPorEnderecoChange();
  }

  private entregaRetirada() {
    if (this.isRetirada || this.isLojaAutonoma) {
      if (this.tipoEntrega?.endereco_retirada) {
        this.titulo = this.tipoEntrega?.endereco_retirada;
      } else if (this.centroDistribuicao) {
        this.titulo =
          this.centroDistribuicao?.endereco.logradouro +
          ', ' +
          this.centroDistribuicao?.endereco.numero;
      }
      this.logradouro = undefined;
      this.bairro = undefined;
      this.complemento = undefined;
    }

    if (!this.titulo) {
      this.titulo = this.endereco?.titulo ?? '';
      this.complemento = this.endereco?.complemento ?? '';
      this.bairro = this.endereco?.bairro ?? '';
      this.logradouro =
        this.endereco?.logradouro + ', ' + this.endereco?.numero;
    }
  }

  private popularPorEnderecoChange() {
    this.titulo = this.endereco?.titulo ?? '';
    this.complemento = this.endereco?.complemento ?? '';
    this.bairro = this.endereco?.bairro ?? '';
    this.logradouro = this.endereco?.logradouro + ', ' + this.endereco?.numero;
  }
}
