import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IBreadCrumb,
  ICarrinho,
  ICompraDesconto,
  IEndereco,
  IHorariosFormatados,
  IPerguntaSobreCompra,
  IProdutoCompra,
  ITaxaServico,
  ITipoEntrega,
  PerguntaTipo,
} from '@vip/core';
import { Router } from '@angular/router';
import { CardPerguntasSobreCompraComponent } from '@vip/ui/card-perguntas-sobre-compra';
import { MessageService } from '@vip/ui/message';

@Component({
    selector: 'vip-resumo-compra',
    templateUrl: './resumo-compra.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ResumoCompraComponent implements AfterViewChecked, OnChanges {
  @Input() compraProdutos: IProdutoCompra[] = [];
  @Input() carrinho?: ICarrinho | null = null;
  @Input() isDesktop = false;
  @Input() perguntas: IPerguntaSobreCompra[] = [];
  @Input() temRespostas = false;
  @Input() temPerguntaObrigatoriaSemResposta = false;
  @Input() tipoEntrega?: ITipoEntrega | null = null;
  @Input() endereco?: IEndereco | null;
  @Input() isRetirada = false;
  @Input() horario?: IHorariosFormatados | null;
  @Input() descontos: ICompraDesconto[] = [];
  @Input() totalDescontos = 0;
  @Input() isLojaAutonoma = false;
  @Input() loading = false;
  @Input() taxaServico: ITaxaServico | null = null;
  @Output() voltarClick = new EventEmitter();
  @Output() realizarPagamento = new EventEmitter();
  @Output() deleteItemClick = new EventEmitter();
  @Output() produtoChanged = new EventEmitter();
  @Input() breadcrumbs: IBreadCrumb[] = [];
  @Output() deleteCarrinhoClick = new EventEmitter<number>();
  @Output() breadCrumbClick = new EventEmitter<string>();
  @Output() responderPergunta = new EventEmitter();
  @Output() verificarItensCarrinho = new EventEmitter();

  @ViewChild(CardPerguntasSobreCompraComponent)
  cardPerguntasSobreCompraComponent?: CardPerguntasSobreCompraComponent;

  disablePagamentoButton = false;
  showMessageValorMinimo = false;
  messageValorMinimo = '';
  constructor(
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private messageService: MessageService
  ) {}
  ngOnChanges(): void {
    if (this.carrinho && this.carrinho.preco < this.carrinho.valor_minimo) {
      const valorMinimoFormatado = this.carrinho.valor_minimo.toLocaleString(
        'pt-br',
        { style: 'currency', currency: 'BRL' }
      );
      this.disablePagamentoButton = true;
      this.showMessageValorMinimo = true;
      this.messageValorMinimo = `O valor mínimo de ${valorMinimoFormatado} da compra nao foi atingido`;
    } else {
      this.disablePagamentoButton = false;
      this.showMessageValorMinimo = false;
    }
  }
  ngAfterViewChecked(): void {
    if (this.horario !== null) this.changeDetector.detectChanges();
  }

  get totalCompra(): number {
    return this.compraProdutos.reduce(
      (prev, current) => prev + current.preco * current.quantidade,
      0
    );
  }

  get taxaEntrega(): number | undefined {
    if (this.tipoEntrega) {
      return this.tipoEntrega.frete - this.tipoEntrega.desconto;
    }
    return;
  }

  get formPerguntaOneQuestionIsValid(): boolean {
    if (
      !this.cardPerguntasSobreCompraComponent ||
      !this.cardPerguntasSobreCompraComponent.formGroup
    )
      return true;

    return this.cardPerguntasSobreCompraComponent.formGroup.valid;
  }

  handleClickAlterarEndereco() {
    if (this.carrinho) this.verificarItensCarrinho.emit();

    this.router.navigate(['/entrega-retirada'], {
      queryParams: { isFromResumoCompra: true },
    });
  }

  handleClickPerguntas() {
    this.router.navigateByUrl('/resumo-compra/perguntas/pagamento');
  }

  handleClickAlterarTipoEntrega() {
    this.router.navigateByUrl('/entrega-retirada/alterar-entrega-retirada');
  }

  responderPerguntaCompra() {
    this.responderPergunta.emit(
      this.cardPerguntasSobreCompraComponent?.formGroup?.value
    );
  }

  continuarComprando(): void {
    this.router.navigateByUrl('/');
  }

  get buttonLabel(): string {
    if (this.perguntas.length > 1) {
      return (!this.temPerguntaObrigatoriaSemResposta && this.temRespostas) ||
        (!this.temPerguntaObrigatoriaSemResposta && !this.temRespostas)
        ? ''
        : 'Responder Perguntas';
    } else if (this.perguntas[0].tipo === PerguntaTipo.TEXTO) {
      return this.temRespostas ? '' : 'Responder Pergunta';
    }
    return '';
  }

  get sideButtonLabel(): string {
    if (this.perguntas.length > 1) {
      return this.temRespostas
        ? 'Editar respostas'
        : !this.temPerguntaObrigatoriaSemResposta
        ? 'Responder'
        : '';
    }
    return this.perguntas[0].tipo === PerguntaTipo.TEXTO && this.temRespostas
      ? 'Editar resposta'
      : '';
  }
}
