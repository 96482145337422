<nav>
  <ul
    role="menubar"
    class="flex justify-between items-center vip-header-menu-principal vip-color-first-default"
  >
    <li
      vipDropdown
      *ngIf="departamentos.length > 0"
      role="none"
      role="menuitem"
      (openChange)="dropdownDepartamentosIsOpen = $event"
    >
      <button
        vipDropdownButton
        data-cy="header-departamentos"
        class="vip-departamentos-toggle"
        [attr.aria-expanded]="dropdownDepartamentosIsOpen"
        aria-haspopup="true"
      >
        <i class="icon-apps text-3xl mr-4"></i>
        <span class="font-bold text-sm">Departamentos</span>
        <i
          class="icon-expand_more text-3xl ml-1"
          [class.expanded]="dropdownDepartamentosIsOpen"
        ></i>
      </button>
      <vip-dropdown-departamentos
        class="z-50"
        vipDropdownContent
        [addClass]="false"
        [items]="departamentos"
        (itemClick)="itemClick($event)"
      >
      </vip-dropdown-departamentos>
    </li>
    <li
      class="vip-vertical-bar"
      *ngIf="departamentos.length > 0"
      role="none"
    ></li>
    <li
      *ngFor="let menuItem of options; let index = index"
      role="none"
      class="itens-submenu"
      (click)="onMenuItemClick(menuItem, index)"
      [ngClass]="{ 'active-menu-principal': activeIndex === index }"
      data-cy="link"
    >
      <span class="text-sm" data-cy="menu-item">
        {{ getMenuOptionValue(index, 'label') }}
      </span>
    </li>
  </ul>
</nav>
